import { createSlice } from "@reduxjs/toolkit";
import { getResourceActions } from "../components/services/resourcePermissionsService";

const resourcePermissionsSlice = createSlice({
  name: "resourcePermissions",
  initialState: [],
  reducers: {
    pushPermissions(state, action) {
      state.push(action.payload);
    },
    restartPermissions(state) {
      state.length = 0;
    }
  },
});

export const { pushPermissions, restartPermissions } = resourcePermissionsSlice.actions;
export default resourcePermissionsSlice.reducer;

export const fetchResourcePermissions = (resourceKey) => async (dispatch, getState) => {
  const state = getState();
  const existingPermissions = state.resourcePermissions;
  if (
    !(existingPermissions?.some(
      (permission) => permission?.resourceKey === resourceKey
    ))
  ) {
    try {
      const permissions = await getResourceActions(resourceKey);
      dispatch(pushPermissions(permissions));
    } catch (error) {
    }
  }
};

export const resourcePermissions = (resourceKey) => (state) => {
  return state.resourcePermissions?.some((permission) => permission?.resourceKey === resourceKey) ?
    state.resourcePermissions.find((permission) =>
      permission?.resourceKey === resourceKey
    ).permissions
    :
    [];
};

export const checkPermission = (actionArray, actionKey) => {
  return actionArray?.some(item => item.key === actionKey);
}
