import Modal from "./Modal";
import classes from "./DeleteConfirmationDialog.module.scss";

const DeleteConfirmationDialog = ({ message, onConfirm, onCancel, className = "", confirmLabel = 'Confirm', cancelLabel = 'Cancel', zIndex }) => {
    return (
        <Modal className={className} zIndex={zIndex} onClose={onCancel}>
            <span className={classes.confirmationMessage}>{message}</span>
            <div className={classes.confirmationButtons}>
                <button id="confirmDeleteButton" className={classes.confirmButton} onClick={onConfirm}>
                    {confirmLabel ?? 'Confirm'}
                </button>
                <button className={classes.cancelButton} onClick={onCancel}>
                    {cancelLabel ?? 'Cancel'}
                </button>
            </div>
        </Modal>
    );
}

export default DeleteConfirmationDialog;