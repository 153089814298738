import { useSelector } from "react-redux";
import useSchedules from "./useSchedules";
import useSites from "./useSites";
import { allLocations } from "../../features/currentDataSlice";
import { useEffect, useState } from "react";
import { alphabetSort, indexBy } from "../../utils/utils";

export const emptyShift = {
  employee: null,
  id: null,
  locationId: null,
  master: { id: 0 },
  observationTime: '',
  overtime: false,
  scheduleTime: null,
  shiftDate: '',
  site: null,
  specialFinalTime: '',
  specialInitialTime: ''
};

const prefillShift = (shift) => (
  shift ?
  {
    ...shift,
    master: shift?.master ??  { id: 0 },
    observationTime: shift?.observationTime ?? '',
    shiftDate: shift?.shiftDate ?? '',
    specialInitialTime: shift?.specialInitialTime ?? '',
    specialFinalTime: shift?.specialFinalTime ?? ''
  } : 
  emptyShift
);

const useShiftForm = ({ shift, currentLocation }) => {
  const [shiftState, setShiftState] = useState(emptyShift);
  const locationSites = alphabetSort({array:currentLocation?.sites?.filter(site=>site.enabled) ?? [], properties:['label']});
  const locationSchedules = currentLocation?.scheduleTimes?.reduce(
    (scheduleObj, schedule) => ({ ...scheduleObj, [schedule.site.id]: [...(scheduleObj[schedule.site.id] ?? []), schedule.scheduleTime]}), {}
  ) ?? {};

  const updateShift = (property, value) => {
    setShiftState(prev => ({ ...prev, [property]: value }));
  }

  useEffect(() => {
    const preShift = prefillShift(shift);
    const defaultSite = locationSites[0] ?? null;
    const defaultSelection = ((preShift?.site === null) ?
      (defaultSite?.id ?
        {
          site: defaultSite,
          scheduleTime: (locationSchedules[defaultSite.id]?.[0] ?? null)
        }
        :
        {}
      )
      :
      ({})
    );
    setShiftState({ ...preShift, ...defaultSelection });
  }, [shift, currentLocation]);

  return { shiftState, locationSites, locationSchedules, updateShift };
}

export default useShiftForm;