import {
  getEmployeesSchedule,
  getEmployeesScheduleFromMonthYear,
} from "../services/userRegisterService";
import moment from "moment";
import "moment-timezone";
import "moment-timezone/data/packed/latest.json"; // Load timezone data
import { getPreviousSunday, shortNextSaturday, shortPreviousSunday } from "../../utils/utils";

export const months = [
  { id: 1, label: "January" },
  { id: 2, label: "February" },
  { id: 3, label: "March" },
  { id: 4, label: "April" },
  { id: 5, label: "May" },
  { id: 6, label: "June" },
  { id: 7, label: "July" },
  { id: 8, label: "August" },
  { id: 9, label: "September" },
  { id: 10, label: "October" },
  { id: 11, label: "November" },
  { id: 12, label: "December" },
];

export async function getCurrentWeekData(
  locationId,
  stringStartDate,
  stringEndDate
) {
  try {
    const data = await getEmployeesSchedule(
      locationId,
      stringStartDate,
      stringEndDate
    );
    return data.load;
  } catch (err) {
    console.log(err.message);
  }
  return null;
}

export async function getCurrentWeekDataFromMonthYear(
  locationId,
  year,
  monthId
) {
  try {
    const data = await getEmployeesScheduleFromMonthYear(
      locationId,
      year,
      monthId
    );
    return data.load;
  } catch (err) {
    console.log(err.message);
  }
  return null;
}

export function getWeekInfo(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const months =
    startMoment.format("MMMM") === endMoment.format("MMMM")
      ? startMoment.format("MMMM")
      : `${startMoment.format("MMM")} - ${endMoment.format("MMM")}`;
  const days = `${startMoment.format("D")} - ${endMoment.format("D")}`;
  const shortNameMonth =
    startMoment.format("MMMM") === endMoment.format("MMMM")
      ? startMoment.format("MMM")
      : `${startMoment.format("MMM")} - ${endMoment.format("MMM")}`;
  return { months, days, shortNameMonth };
}

export function getYears() {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  return [
    { id: 1, label: currentYear.toString() },
    { id: 2, label: nextYear.toString() },
  ];
}

export function getCurrentMonth(months) {
  var now = new Date();
  var current_month = now.getMonth() + 1;
  const monthValue = months.find((month) => month.id === current_month);
  return monthValue;
}

export function getWeek(stringDate) {
  return [shortPreviousSunday(stringDate), shortNextSaturday(stringDate)];
}

export function loadYearMonth() {
  let storedDate = localStorage.getItem('lastViewed');
  let storedLocation = parseInt(localStorage.getItem('lastLocation'));
  if (storedDate) {
    try {
      let date = new Date(storedDate.slice(0,10)+"T00:00+00:00");
      let year = getYears().find(y=>y.label === date.getUTCFullYear().toString());
      let month = months.find(m => m.id === date.getUTCMonth() + 1);
      let [startDate, endDate] = getWeek(date.toISOString().slice(0,10));
      return [year, month, startDate, endDate, storedLocation];
    } catch (error) {
      let today = new Date();
      let [startDate, endDate] = getWeek(today.toISOString().slice(0,10));
      let year = getYears().find(y => y.label === today.getUTCFullYear().toString());
      let month = months.find(m => m.id === today.getUTCMonth() + 1);
      return [year, month, startDate, endDate, storedLocation];
    }
  } else {
    let today = new Date();
    let [startDate, endDate] = getWeek(today.toISOString().slice(0,10));
    let year = getYears().find(y => y.label === today.getFullYear().toString());
    let month = months.find(m => m.id === today.getMonth() + 1);
    return [year, month, startDate, endDate, storedLocation];
  }
}

export function getCurrentYear() {
  let now = new Date();
  return now.getFullYear();
}

export function getFiveWeeksBefore(startDate) {
  const weeks = [];
  // Calculate the start date of the first week
  const firstWeekStartDate = moment(startDate).subtract(35, "days");
  // Loop through the four weeks and add the start date of each week to the array
  for (let i = 0; i < 5; i++) {
    const weekStartDate = moment(firstWeekStartDate).add(i * 7, "days");
    if (weekStartDate <= startDate) {
      const endDate = moment(weekStartDate).add(6, "days");
      const weekInfo = getWeekInfo(weekStartDate, endDate);
      const week = {
        id: i,
        startDate: weekStartDate,
        endDate: endDate,
        month: weekInfo.months,
        day: weekInfo.days,
        shortNameMonth: weekInfo.shortNameMonth,
      };
      weeks.push(week);
    }
  }
  return weeks;
}

export function getNextFiveWeeks(startDate) {
  const weeks = [];
  // Loop through the next five weeks and add the start and end dates to the array
  for (let i = 1; i <= 5; i++) {
    const weekStartDate = moment(startDate).add(7 * i, "days");
    const weekEndDate = moment(weekStartDate).add(6, "days");
    const weekInfo = getWeekInfo(weekStartDate, weekEndDate);
    const week = {
      id: i,
      startDate: weekStartDate,
      endDate: weekEndDate,
      month: weekInfo.months,
      day: weekInfo.days,
      shortNameMonth: weekInfo.shortNameMonth,
    };
    weeks.push(week);
  }
  return weeks;
}

export function getWeekDays(startDate, lenght = 7) {
  const today = moment();
  const weekDays = Array.from({ length: lenght }, (_, i) => {
    const date = moment(startDate).add(i, "days");
    return {
      id: i,
      name: date.format("ddd"),
      day: date.format("D"),
      date,
      isToday: date.isSame(moment(),'day'),
    };
  });
  return weekDays;
}

export function getWeekMonthDays(startDate, lenght) {
  const today = moment();
  const weekDays = Array.from({ length: lenght }, (_, i) => {
    const date = moment(startDate).add(i, "days");
    return {
      id: i,
      name: date.format("ddd"),
      day: date.format("D"),
      isToday: date.isSame(moment(),'day'),
      month: date.format("MMMM"),
      year: date.format("YYYY"),
    };
  });
  return weekDays;
}

export function transformDateTimeZone(date, timeZone) {
  const myLocalDate = moment.utc(date).startOf('day');
  return myLocalDate;
}

export function getCurrentWeekDates() {
  const today = moment();
  const startDate = today.clone().startOf("week").set("hour", 1);
  const endDate = today.clone().endOf("week").set("hour", 1);
  const weekInfo = getWeekInfo(startDate, endDate);
  return {
    startDate: startDate,
    endDate: endDate,
    month: weekInfo.months,
    day: weekInfo.days,
    shortNameMonth: weekInfo.shortNameMonth,
  };
}