import React from 'react';

import classes from './deleteComment.module.scss';



const DeleteComments = (props) => {

    return (
        <>
            <button className={classes.buttonContainer} id="deleteComment" onClick={props.onClick}> 
                <span className={`icon-delete ${classes.iconDelete}`}></span>
            </button>
            

        </>
    );
};

export default DeleteComments;
