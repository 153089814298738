import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function insertCurrentDataCosmo(objData) {
  try {
    const token = getToken();
    const response = await fetch(`${API_BASE_URL}/insert-current-data-cosmodb`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(objData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}