import { Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.scss";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} style={typeof(props.zIndex) === 'number' ? {zIndex: props.zIndex}:{}}></div>;
};

const ModalOverlay = (props) => {
  const classesProps = `${classes.modal} ${props.className ? (classes[props.className] ?? props.className) : ""}`;
  return (
    <div className={classesProps} id={props.id ?? ''}  style={typeof(props.zIndex) === 'number' ? {zIndex: props.zIndex + 1}:{}}>
      <div className={`${classes.content} ${props.disabled === true ? classes.disabledContent: ""} ${props.contentClassName ?? ''}`}>{props.children}</div>
    </div>
  );
};

const portalElemId = document.getElementById("overlays");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} zIndex={props.zIndex}/>,
        portalElemId
      )}
      {ReactDOM.createPortal(
        <ModalOverlay className={props.className} contentClassName={props.contentClassName} id={props.id ?? ''} disabled={props.disabled ?? false} zIndex={props.zIndex}>{props.children}</ModalOverlay>,
        portalElemId
      )}
    </Fragment>
  );
};

export default Modal;
