import Modal from "./Modal";
import classes from "./InformationDialog.module.scss";

const InformationDialog = ({ message, onCancel }) => {
  return (
    <Modal onClose={onCancel}>
      <span className={classes.confirmationMessage}>{message}</span>
      <div className={classes.confirmationButtons}>
        <button className={classes.confirmButton} onClick={onCancel}>
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default InformationDialog;