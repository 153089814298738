import { skillListTest } from "../../utils/utils";
import UserAvatar from "../UI/UserAvatar";
import EmployeeSkills from "../standard/EmployeeSkills";
import classes from "./UserInformation.module.scss";

const UserInformation = (props) => {

  const iconClasses = `${classes.icon} ${classes[props.userInfoClass]}`

  return (props.size === 'small' ?
    <div className={classes.smallSizeContainer}>
      <UserAvatar employee={props.user} size="small" addMargin={!(props.showSkillsUnderShortName)}/>
      <span>{props.user?.userName ?? ''}</span>
    </div>
    :
    <div className={classes.userContainer}>
      <div className={classes.userInfoContainer}>
        <UserAvatar employee={props.user} size="small"/>
        {props.showSkillsUnderShortName && <EmployeeSkills  skillList={props.user.skills} badgeShape="circle" showLabel={false}/>}
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.name}>{props.user && props.user.userName}</div>
        <div className={classes.id}>
          {props.user?.relationCode ?? ''}
          <span className={classes.planeId}>
            {props.user?.license ? ` - ${props.user.license}`: ''}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
