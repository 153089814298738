import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";
import { cleanBy } from "../../utils/utils";
import store from '../../app/store';
import { showToast } from "../../features/toastNotificationSlice";
import { setShowErrorPage } from "../../features/currentDataSlice";
// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export function transformFetchLocationData(data) {
  const locations = data.load.map(location => ({
    ...location,
    value: location.locationCode,
    label: location.name,
    sites: location.sites.map(site => ({
      ...site,
      label: site.name,
      schedules: location.scheduleTimes.filter(schedule => schedule?.site?.id === site.id).map(schedule=>schedule?.scheduleTime?.id)
    }))
  }));
  return locations;
}

export async function getAllLocations() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/get-locations/`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === 403) {
      return 403;
    } else if (data.responseCode !== 200){
      console.log(`Error while retrieving the locations: ${data.error} ${data.status} ${data}`);
      throw new Error(`Error while retrieving the locations: ${data.error}`);
    } else {
      let response = transformFetchLocationData(data);
      response = cleanBy(response, ['label', 'timeZone']);
      return response;
    }
  } catch (error) {
    console.log(error.message);
    store.dispatch(showToast({ type: 'warning', message: error.message, duration:'keep-open'}));
    store.dispatch(setShowErrorPage(true));
  }
}