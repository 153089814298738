import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function getAllLeaves() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/get-employment-leaves/`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export async function getLeavesAdmin() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(
      `${API_BASE_URL}/get-employment-leaves-admin/`,
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export async function getEmployeeLeaves(employeeId) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(
      `${API_BASE_URL}/get-employee-leaves?employeeId=${employeeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export async function createEmployeeLeave(data) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/create-employee-leave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(
        "Something went wrong! Couldn't create employeee vacation"
      );
    }
    const loadedData = await response.json();
    return loadedData;
  } catch (error) {
    console.log(error.message);
  }
}

export async function createEmployeeLeaveDeleteShift(data) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(
      `${API_BASE_URL}/create-employee-leave-delete-shifts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!response.ok) {
      throw new Error(
        "Something went wrong! Couldn't create employeee vacation"
      );
    }
    const loadedData = await response.json();
    return loadedData;
  } catch (error) {
    console.log(error.message);
  }
}

export async function deleteEmployeeLeave(leaveId) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(
      `${API_BASE_URL}/delete-employee-leave?leaveId=${leaveId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}