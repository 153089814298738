import { useState, useEffect } from "react";
import classes from "./NoUsers.module.scss";
import noDataImg from "../../assets/images/no_employees.png";

const NoUsers = ({top, bottom}) => {
  const [height, setHeight] = useState(0);
  const [weekelements, setWeekElements] = useState([]);

  useEffect(() => {
    const marginTop = top ? top : 160;
    const marginBottom = (bottom === 0 || bottom) ? bottom : 50;
    const windowHeight = window.innerHeight;
    const divHeight = windowHeight - marginTop - marginBottom;
    setHeight(divHeight);

    const size = Math.ceil(divHeight / 100);
    const emptySlots = [];
    for (let i = 0; i < size; i++) {
      emptySlots.push(
        <div key={i} className={classes.weekDaysContainer}>
          <div className={classes.emptySlot}>
            <i className={`icon-options ${classes.iconOptions}`}></i>
          </div>
          <div className={classes.emptySlot}>
            <i className={`icon-options ${classes.iconOptions}`}></i>
          </div>
          <div className={classes.emptySlot}>
            <i className={`icon-options ${classes.iconOptions}`}></i>
          </div>
          <div className={classes.emptySlot}>
            <i className={`icon-options ${classes.iconOptions}`}></i>
          </div>
          <div className={classes.emptySlot}>
            <i className={`icon-options ${classes.iconOptions}`}></i>
          </div>
          <div className={classes.emptySlot}>
            <i className={`icon-options ${classes.iconOptions}`}></i>
          </div>
          <div className={classes.emptySlot}>
            <i className={`icon-options ${classes.iconOptions}`}></i>
          </div>
        </div>
      );
    }
    setWeekElements(emptySlots);
  }, [top, bottom]);

  return (
    <div style={{ height: `${height}px` }} className={classes.noDataContainer}>
      <div
        className={classes.container}
      >
        <img src={noDataImg} alt="" />
        <div className={classes.textContainer}>
          <span>
            There are no users to show.
          </span>
        </div>
      </div>
      <div className={classes.weekRowContainer} >{weekelements}</div>
    </div>
  );
};

export default NoUsers;
