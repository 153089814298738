import classes from './TextArea.module.scss';

const TextArea = ({ name, value, onChange, placeholder, disabled, showCount, maxLength, maxLengthMessage, onFocus, onBlur }) => {
  const fallbackValue = value ?? '';
  return (
    <div className={classes.wrapper}>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {showCount && <div className={classes.lengthIndicator}>
        {fallbackValue.length < maxLength ? `${fallbackValue.length ?? ''}/${maxLength}` : maxLengthMessage}
      </div>}
    </div>
  )

}

export default TextArea;