import { createSlice } from "@reduxjs/toolkit";

const publishSlice = createSlice({
  name: 'publish',
  initialState: [],
  reducers: {
    addWeek: (state, action) => {
      const weekDate = action.payload;
      return [...state, weekDate]
    },
    dropWeek: (state, action) => {
      const weekDate = action.payload;
      return state.filter(date => date !== weekDate);
    }
  }
});

export const {addWeek, dropWeek} = publishSlice.actions;
export const selectPendingWeeks = (state) => state.publish;
export default publishSlice.reducer;