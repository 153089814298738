import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Filter.module.scss";
import {
  toggleFilterCategory,
  categoriesState,
  openCategoriesState,
  fetchCategoriesOptions,
  updateFilterCategory,
  setSelectedFilters,
  clearFilter,
} from "../../features/filterSlice";
import { selectedLocationState } from "../../features/currentDataSlice";

const Filter = ({ className, onClose }) => {
  const dispatch = useDispatch();
  const categories = useSelector(categoriesState);
  const openCategories = useSelector(openCategoriesState);
  const showClearButton = openCategories.some((section) =>
    section.categories?.some((category) => category.selected)
  );
  const selectedLocation = useSelector(selectedLocationState);
  const locationId = selectedLocation.id;

  const handleToggleCategory = (categoryId) => {
    dispatch(toggleFilterCategory(categoryId));
  };

  const clearFilterClick = () => {
    dispatch(clearFilter());
  };

  useEffect(() => {
    if (openCategories.length === 0 && locationId) {
      dispatch(fetchCategoriesOptions(locationId));
    }
  }, [dispatch, openCategories, locationId]);

  const handleCategorySelection = (categoryId, parentCategory) => {
    const foundParentCategory = openCategories.find(
      (parent) => parent.id === parentCategory.id
    );
    const foundCategory = foundParentCategory.categories?.find(
      (category) => category.id === categoryId
    );
    const filter = foundCategory.endorsements ? foundCategory.endorsements : [];
    dispatch(updateFilterCategory({ foundCategory, parentCategory, filter }));
  };

  const applyFilterHandler = () => {
    dispatch(setSelectedFilters(openCategories));
    onClose();
  };

  const container = `${classes.filterContainer} ${classes[className]}`;

  return (
    <div className={container}>
      <div className={classes.title}>
        <div className={classes.filterIcon}>
          <span className={`icon-filter ${classes.filterIcon}`}></span>
        </div>
        <span className={classes.titleText}>Filters</span>
        <div className={classes.buttonsContainer}>
          {showClearButton && (
            <button className={classes.clearButton} onClick={clearFilterClick}>
              {" "}
              Clear All ✕
            </button>
          )}
          <button onClick={applyFilterHandler}>Ok</button>
        </div>
        <span
          onClick={() => {
            onClose();
          }}
          className={`${classes.closeFilterIcon} icon-close`}
        ></span>
      </div>
      {categories.map((category) => (
        <div
          key={category.id}
          className={`${classes.filterCategory} ${
            openCategories.includes(category.id)
              ? classes.openCategory
              : classes.closedCategory
          }`}
        >
          <div
            className={`${classes.filterCategoryHeader} ${
              openCategories.includes(category.id)
                ? classes.openCatHeader
                : classes.closedCatHeader
            }`}
            onClick={() => handleToggleCategory(category.id)}
          >
            <span className={classes.CategoryName}>{category.name}</span>
            <span
              className={`${
                openCategories.includes(category.id)
                  ? `${classes.showLess} icon-less`
                  : `${classes.showMore} icon-more`
              }`}
            ></span>
          </div>
          {openCategories.includes(category.id) && (
            <div className={classes.filterCategoryBody}>
              <ul>
                {openCategories.find((item) => item.id === category.id)
                  ?.categories.length === 0 ? (
                  <p>No categories found</p>
                ) : (
                  openCategories
                    .find((item) => item.id === category.id)
                    ?.categories?.map((cat) => (
                      <li
                        key={cat.id}
                        className={`${
                          cat.selected === true ? classes.isItemSelected : ""
                        }`}
                      >
                        <label className={classes.container}>
                          <input
                            type="checkbox"
                            checked={cat.selected || false}
                            onChange={() =>
                              handleCategorySelection(cat.id, category)
                            }
                          />
                          <span className={classes.checkmark}></span>
                          {cat.name ? cat.name : cat.groupName}
                        </label>
                      </li>
                    ))
                )}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Filter;