import { useState, useRef, useEffect } from "react";
import UserInformation from "../Users/UserInformation";
import classes from "./Dropdown.module.scss";

const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const disabled = props.disabled ?? false;
  const contentHeight = props.contentHeight;

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    props.onClick(option)
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOption(props.defaultValue);
  }, [props.defaultValue]);

  const toggleDropdownHandler = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const dropdownToggleClass = `${classes.dropdownMenu} ${classes[props.dropdownContainer]} ${isOpen ? classes.active : classes.inactive
    }`;

  const dropdownArrowToggle = isOpen
    ? `icon-up ${classes.dropdownArrow}`
    : `icon-down ${classes.dropdownArrow}`;

  const locationDropdown = `${classes.locationDropdown} ${classes[props.className]} ${props.outlined ? classes.outlined: ''}`;

  const optionsClass = `${classes.optionsList} ${classes[props.userList]}`;

  const iconClass = `${props.icon} ${classes[props.iconClass]}`;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let displayProperty = props.displayProperty ?? 'name';
  let filteredOptions = [];
  if (props.options?.length > 0) {
    filteredOptions = props.options?.filter(
      option => option?.[displayProperty]?.toLowerCase().includes(searchQuery.toLowerCase())
    ) ?? [];
  }

  const filteredUsers = props.users ? props.users.filter((option) =>
    option.userName.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  const adjustListPosition = () => {
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    const windowRect = document.querySelector('#' + props.windowId)?.getBoundingClientRect() ??
      { top: 0, left: 0, x: 0, y: 0, height: window.innerHeight, width: window.innerWidth, bottom: window.innerHeight, right: window.innerWidth };
    let availableTop = dropdownRect.top - windowRect.top - 20;
    let availableBottom = windowRect.bottom - dropdownRect.bottom - 20;
    let availableLeft = dropdownRect.left - windowRect.left - 20;
    let availableRight = dropdownRect.right - windowRect.right - 20;
    let requestedHeight = windowRect.height;
    if (contentHeight) {
      if (contentHeight.includes('%')) {
        requestedHeight = ((parseFloat(contentHeight) / 100) * windowRect.height) + 'px';
      } else {
        requestedHeight = dropdownContentRef.current.style.maxHeight = contentHeight;
      }
    }
    if (availableTop <= availableBottom) {
      dropdownContentRef.current.style.maxHeight = (availableBottom < requestedHeight ? availableBottom : requestedHeight) + 'px'
      dropdownContentRef.current.style.top = '100%';
    } else {
      dropdownContentRef.current.style.maxHeight = (availableTop < requestedHeight ? availableTop : requestedHeight) + 'px';
      dropdownContentRef.current.style.top = '-' + (dropdownContentRef.current.getBoundingClientRect().height + 5) + 'px';
    }
    if (availableLeft <= availableRight) {
      dropdownContentRef.current.style.left = '0';
    } else {
      dropdownContentRef.current.style.right = '0';
    }
  };

  useEffect(() => {
    if (isOpen) {
      adjustListPosition();
    }
  }, [isOpen, filteredOptions, filteredUsers]);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen])

  return (
    <div ref={dropdownRef} className={locationDropdown}>
      <div className={`${classes.dropdown} ${disabled ? classes.disabled : ''}`}>
        <div className={classes.dropdownToggle} onClick={toggleDropdownHandler}>
          {(disabled && props.disabledPlaceholder) ?
            <div className={classes.disabledPlaceholder}>{props.disabledPlaceholder ?? ''}</div> :
            <>
              {props.icon && <span className={iconClass} />}
              {props.showSearch ?
                (isOpen ? <input className={props.theme === 'light' ? classes.light : classes.dark} type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} onClick={(e) => e.stopPropagation()} autoFocus /> :
                  <div className={classes.selectedItemText}>
                    {!props.showUserInfo && selectedOption ? (props.getLabel ? props.getLabel(selectedOption) : (selectedOption.labelDropdown ? selectedOption.labelDropdown : (selectedOption[displayProperty]))) : ""}
                    {props.showUserInfo && <UserInformation userInfoClass={props.userInfoClass} user={selectedOption} showSkillsUnderShortName={false}></UserInformation>}
                  </div>)
                :
                <div className={classes.selectedItemText}>
                  {!props.showUserInfo && selectedOption ? (props.getLabel ? props.getLabel(selectedOption) : (selectedOption.labelDropdown ? selectedOption.labelDropdown : (selectedOption[displayProperty]))) : ""}
                  {props.showUserInfo && <UserInformation userInfoClass={props.userInfoClass} user={selectedOption} showSkillsUnderShortName={false}></UserInformation>}
                </div>
              }
            </>}
          <span className={dropdownArrowToggle} />
        </div>
        <div className={dropdownToggleClass} ref={dropdownContentRef}>
          <ul>
            {!props.showUserInfo && filteredOptions.map((item) => (
              <li className={optionsClass} onClick={() => handleOptionClick(item)} key={item.id}>
                {props.getLabel ?
                  <div className={classes.optionLabel}>{props.getLabel(item)}</div> :
                  <p>{item.labelDropdown ? item.labelDropdown : (item[displayProperty])}</p>
                }
              </li>
            ))}
            {props.showUserInfo && filteredUsers.map((user) => (
              <li className={optionsClass} onClick={() => handleOptionClick(user)} key={user.id}>
                <UserInformation userInfoClass={props.userInfoClass} user={user} showSkillsUnderShortName={false}></UserInformation>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
