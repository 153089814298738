import React, { Fragment, useContext, useState } from "react";
import classes from "./UserCard.module.scss";
import { EmployeeInfo } from "../permissions/EmployeeInfo";
import { useDispatch } from "react-redux";
import { selectEmployeeHandler } from "../../features/shiftPatternSlice";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import {
  resourcePermissions,
  checkPermission,
} from "../../features/resourcePepermissionsSlice";

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import TransferUserModal from "./TransferUserModal";
import UserAvatar from "../UI/UserAvatar";
import MultiOptionDialog from "../UI/MultiOptionDialog";
import { cancelEmployeeTransfer } from "../services/userRegisterService";
import UserRegisterContext from "../store/user-register-context";
import { showToast } from "../../features/toastNotificationSlice";
import { fetchCurrentMonthView, reloadMonth } from "../../features/monthViewSlice";
import { selectedLocationState } from "../../features/currentDataSlice";
import Checkbox from "../UI/Checkbox";

const UserCard = (props) => {
  const anchorRef = React.useRef(null);
  const [openEmployeeInfoModal, setOpenEmployeeInfoModal] = useState(false);
  const [openEmployeeMenu, setOpenEmployeeMenu] = useState(false);
  const [openTransferUserModal, setOpenTransferUserModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const userRegisterCtx = useContext(UserRegisterContext);
  const selectedLocation = useSelector(selectedLocationState);


  const employeeInfoModalHandler = () => {
    setOpenEmployeeInfoModal(!openEmployeeInfoModal);
  };

  const openEmployeeMenuHandler = () => {
    setOpenEmployeeMenu(!openEmployeeMenu);
  };

  const transferUserModalHandler = () => {
    setOpenTransferUserModal(!openTransferUserModal)
  };

  const cancelTransferHandler = (user) => {
    setOpenDialog(user);
  }

  const onDeleteTransfer = async () => {
    dispatch(showToast({ type: 'info', message: 'Removing transfer...' }));
    let successCount = await props.userTransfers?.transferDates?.map(async transfer => {
      const response = await cancelEmployeeTransfer(transfer.transferId, null);
      return response?.responseCode === 200;
    });
    let success = await Promise.all(successCount);
    if (success.every(i => i)){
      try{
        userRegisterCtx.changeWeek(userRegisterCtx.selectedWeek);
      } catch (error) {

      }
      let midMonthDate = new Date(props.employee.shifts[Math.floor(props.employee.shifts.length / 2)].shiftDate);
      dispatch(
        fetchCurrentMonthView({
          monthId: midMonthDate.getMonth() + 1,
          locationId: selectedLocation.id,
          year: midMonthDate.getFullYear(),
        })
      );
      dispatch(showToast({ type: 'success', message: 'Transfer removed' }));
    } else {
      dispatch(showToast({ type: 'warning', message: 'Something went wrong removing the transfer...' }));
    }
    setOpenDialog(null);
  }

  const contactCard = `${classes.contactCard} ${classes[props.className]} ${props.greyBackground ? classes.greyBackground : ''}`;

  const selectedDayToggle = () => {
    dispatch(selectEmployeeHandler(props.employee));
  };

  const currentResourcePermissions = useSelector(
    resourcePermissions("week-view")
  );

  const overtime = props.employee.shifts.filter(
    (shift) => shift.overtime
  ).length;
  return (
    <Fragment>
      <div
        className={contactCard}
        onClick={() =>
          props.openUserRegisterShifts
            ? props.openUserRegisterShifts(props.employee.id, props.isTransfer)
            : null
        }
      >
        <div className={classes.contactCardInfoContainer}>
          {props.showfilterChecked === true ? (
            <div className={classes.selectEmployeeContainer}>
              <label className={classes.container}>
                <Checkbox checked={props.employee.selected} onChange={()=>selectedDayToggle()}/>
                {/* <input
                  type="checkbox"
                  checked={
                    props.employee.selected ? props.employee.selected : false
                  }
                  onChange={() => selectedDayToggle()}
                /> */}
                {/* <span className={classes.checkmark}></span> */}
              </label>
            </div>
          ) : props.showMoveIcon === false ? (
            ""
          ) : (
            ""
          )}
          <UserAvatar employee={props.employee} addMargin={true} hideSkills={props.hideSkills ?? false} />
          <div>
            <div className={classes.name}>{props.employee.userName}</div>
            <div className={classes.id}>
              {props.employee?.relationCode ?? ''}
              <span className={classes.planeId}>
                {props.employee?.license ? ` - ${props.employee.license}` : ''}
              </span>
            </div>
          </div>
        </div>

        <div className={classes.iconsContainer}>
          {overtime > 0 && (
            <Tooltip
              title={<div style={{ fontSize: "12px" }}>Overtime Count</div>}
              placement="bottom"
              arrow
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "-10px, 0px", // Adjust the offset values as per your requirement
                    },
                  },
                },
              }}
            >
              <div className={classes.overtimeIconContainer}>
                <div className={classes.iconOvertimeContainer}>
                  <span className={`icon-overtime-clock ${classes.overtimeIcon}`}></span>
                </div>
                <div className={classes.overtimeNumber}>{overtime}</div>
              </div>
            </Tooltip>
          )}
          {(checkPermission(currentResourcePermissions, "employee-info") || checkPermission(currentResourcePermissions, "transfer-user")) &&
            (props.showEmployeeCard === false ? null : (
              <div
                onClick={openEmployeeMenuHandler}
                className={classes.iconUserCardContainer}
              >
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={
                    openEmployeeMenu ? "composition-menu" : undefined
                  }
                  aria-expanded={openEmployeeMenu ? "true" : undefined}
                  aria-haspopup="true"

                >
                  <span className={`icon-user-card ${classes.iconUserCard}`} />
                </Button>
              </div>
            ))}
        </div>
      </div>
      {openEmployeeMenu && (
        <Popper
          open={openEmployeeMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          sx={{ zIndex: 5 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper
                sx={{ zIndex: 10 }}
              >
                <ClickAwayListener onClickAway={openEmployeeMenuHandler}>
                  <MenuList
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    {checkPermission(currentResourcePermissions, "employee-info") && <MenuItem onClick={employeeInfoModalHandler}>
                      Employee Info
                    </MenuItem>}
                    {checkPermission(currentResourcePermissions, "transfer-user") && !props.isTransfer && <MenuItem onClick={transferUserModalHandler}>
                      Transfer User
                    </MenuItem>}
                    {checkPermission(currentResourcePermissions, "delete-transfer-user") && props.isTransfer &&
                      <MenuItem onClick={() => cancelTransferHandler(props.employee)}>Cancel Transfer</MenuItem>
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {openEmployeeInfoModal && (
        <EmployeeInfo
          onClose={employeeInfoModalHandler}
          employee={props.employee}
        ></EmployeeInfo>
      )}
      {openTransferUserModal && (
        <TransferUserModal
          onClose={transferUserModalHandler}
          employee={props.employee}
        ></TransferUserModal>
      )}
      {openDialog && <MultiOptionDialog
        message={`Are you sure you want to cancel the transfer for ${openDialog.userName}?`}
        options={[
          { label: 'Confirm', callback: onDeleteTransfer },
        ]}
        onClose={() => setOpenDialog(false)}
      />}
    </Fragment>
  );
};

export default UserCard;
