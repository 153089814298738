import React, { useState, useEffect, useCallback } from 'react';
import classes from './AddComments.module.scss';
import { getComments } from '../services/commentsService';
import { shiftComments } from '../../features/shiftCommentsSlice';
import { useSelector, } from 'react-redux';
import CommentsModal from './CommentsModal';

const AddComments = (props) => {

  const shiftId = props.shiftId;
  const iconButton = props.iconButton ?? false;
  const hideWhenEmpty = props.hideWhenEmpty ?? false;
  const [open, setOpen] = useState(false);
  const iconFontSize = props.iconFontSize ?? props.style?.fontSize ?? '0.8em';
  const [comments, setComments] = useState([]);
  const commentsUpdate = useSelector(shiftComments);
  const showBadge = props.showBadge ?? false;
  const buttonStyle = { ...props.style, fontSize: iconFontSize, ...((showBadge) ? { width: '0.7em' } : {}) };


  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) {
      try {
        props.onClose();
      } catch {
        ;
      }
    }
  };

  const fetchComments = useCallback(async () => {
    const fetchedComments = await getComments(shiftId);
    if (Array.isArray(fetchedComments)) {
      setComments(fetchedComments);
    } else {
      console.error('Invalid comments data:', fetchedComments);
    }
  }, [shiftId, setComments]);

  useEffect(() => {
    fetchComments();
  }, [open, shiftId, fetchComments]);

  useEffect(() => {
    if (commentsUpdate.id === shiftId) {
      fetchComments();
    }
  }, [commentsUpdate, fetchComments, shiftId]);

  return (
    <>{iconButton ?
      (!hideWhenEmpty || (comments.length > 0)) &&
      <div className={classes.iconButtonContainer} onClick={handleClickOpen}>
        <span className={'icon-comment'} style={buttonStyle} ></span>
        {showBadge && <span className={`icon-circle ${classes.badge}`}></span>}
      </div>
      :
      <button onClick={handleClickOpen} className={classes.commentsButton} id="addCommentsButton">
        <span className={`icon-comment ${classes.commentIcon}`}></span>
        <div>Comments: {comments.length}</div>
      </button>}
      {open && <CommentsModal shiftId={props.shiftId} onClose={handleClose} />}

    </>
  );
};

export default AddComments;
