import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { logOut } from "./userSlice";
import { getMonthViewData } from "../components/services/monthViewService";
import {
  transformDateTimeZone,
  getWeekMonthDays,
} from "../components/services/dateTimeService";
import { setLoading } from "./loadingSlice";
import { restartPermissions } from "./resourcePepermissionsSlice";
import { getEmployeesSchedule } from "../components/services/userRegisterService";
import { setShowErrorPage } from "./currentDataSlice";


export const monthViewslice = createSlice({
  name: "monthView",
  initialState: {
    formattedMonthDays: [],
    currentMonthData: [],
    status: "idle",
    error: null
  },
  reducers: {
    setFormattedMonthDays: (state, action) => {
      state.formattedMonthDays = action.payload;
    },
    deleteShiftUpdateCurrentData: (state, action) => {
      const { userId, shiftId } = action.payload;
      const updatedCurrentMonthData = state.currentMonthData.map(
        (monthData) => {
          const updatedEmployees = monthData.employees.map((employee) => {
            if (employee.employee.id === userId) {
              const updatedShifts = employee.employee.shifts.map(
                (shift) => shift.id === shiftId ? { shiftDate: shift.shiftDate, avaibleInTransfer:true } : shift
              );
              const updatedEmployee = {
                employee: {
                  ...employee.employee,
                  shifts: updatedShifts,
                },
                fromLocation: employee.fromLocation
              };
              return updatedEmployee;
            }
            return employee;
          });
          return { ...monthData, employees: updatedEmployees };
        }
      );
      state.currentMonthData = updatedCurrentMonthData;
    },
    addNewShiftUpdateCurrentData: (state, action) => {
      const { userId, shiftIndex, response } = action.payload;
      const updatedCurrentMonthData = state.currentMonthData.map(
        (monthData) => {
          const updatedEmployees = monthData.employees.map((employee) => {
            if (employee.employee.id === userId) {
              const updatedShifts = employee.employee.shifts.map(
                (shift, index) => {
                  if (index === shiftIndex) {
                    const data = response;
                    const updateSite = {
                      ...data.site,
                      labelDropdown: data.site.name
                    }
                    return {...data,
                      id: data.id,
                      site: updateSite,
                      shiftDate: data.shiftDate,
                      overtime: data.overtime,
                      leave: data.leave,
                      transferTo: data.transferTo,
                      shortName: data.shortName,
                    };
                  }
                  return shift;
                }
              );
              const updatedEmployee = {
                employee: {
                  ...employee.employee,
                  shifts: updatedShifts,
                },
                fromLocation: employee.fromLocation
              };
              return updatedEmployee;
            }
            return employee;
          });
          return { ...monthData, employees: updatedEmployees };
        }
      );
      state.currentMonthData = updatedCurrentMonthData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentMonthView.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCurrentMonthView.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.currentMonthData = action.payload;
      })
      .addCase(fetchCurrentMonthView.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder.addCase(fetchWeek.fulfilled, (state, action) => {
      let response = action.payload.response;
      if (response.responseCode === 200) {
        let newGroups = response.load.groups;
        if (response.load.transfers.length > 0) {
          newGroups.push({ id: 'transfer', groupName: "Users Transfers", employees: response.load.transfers })
        }
        let currentState = JSON.parse(JSON.stringify(current(state)));
        let groups = currentState.currentMonthData;
        for (let group of groups) {
          let newGroup = newGroups.find(g => g.id === group.id);
          if (newGroup) {
            for (let employee of group.employees) {
              let newEmployee = newGroup.employees.find(e => e.employee.id === employee.employee.id);
              if (newEmployee) {
                employee.employee.shifts = action.payload.head ?
                  [...newEmployee.employee.shifts, ...employee.employee.shifts] :
                  [...employee.employee.shifts, ...newEmployee.employee.shifts];
              } else {
                let referenceshifts = newGroups[0].employees[0].employee.shifts.map(s => { return { shiftDate: s.shiftDate, availableInTransfer: false } });
                employee.employee.shifts = action.payload.head ?
                  [...referenceshifts, ...employee.employee.shifts] :
                  [...employee.employee.shifts, ...referenceshifts];
              }
            }
            let extraEmployees = newGroup.employees.filter(e => !(group.employees.map(e2 => e2.employee.id).includes(e.employee.id)))
              .map(e => {
                e.employee.shifts = action.payload.head ?
                  [...e.employee.shifts, ...group.employees[0].employee.shifts.slice(7).map(s => { return { shiftDate: s.shiftDate, availableInTransfer: false } })] :
                  [...group.employees[0].employee.shifts.slice(0, -7).map(s => { return { shiftDate: s.shiftDate, availableInTransfer: false } }), ...e.employee.shifts];
                return e;
              })
            group.employees = [...group.employees, ...extraEmployees];
          } else {
            let referenceshifts = newGroups[0].employees[0].employee.shifts.map(s => { return { shiftDate: s.shiftDate, availableInTransfer: false } });
            for (let employee of group.employees) {
              employee.employee.shifts = action.payload.head ?
                [...referenceshifts, ...employee.employee.shifts] :
                [...employee.employee.shifts, ...referenceshifts];
            }
          }

        }
        let extraGroups = newGroups.filter(g => !(groups.map(g2 => g2.id).includes(g.id)))
          .map(g => {
            g.employees.forEach(e => e.employee.shifts = action.payload.head ?
              [...e.employee.shifts, ...groups[0].employees[0].employee.shifts.slice(7).map(s => { return { shiftDate: s.shiftDate, availableInTransfer: false } })] :
              [...groups[0].employees[0].employee.shifts.slice(0, -7).map(s => { return { shiftDate: s.shiftDate, availableInTransfer: false } }), ...e.employee.shifts]
            );
            return g
          });
        groups = [...extraGroups, ...groups]
        let updatedMonthDays = action.payload.head ?
          [...getWeekMonthDays(action.payload.startDateString, 7), ...currentState.formattedMonthDays] :
          [...currentState.formattedMonthDays, ...getWeekMonthDays(action.payload.startDateString, 7)];
        state.formattedMonthDays = updatedMonthDays;
        state.currentMonthData = groups;
        if (action.payload.head) { localStorage.setItem('loadingHead', 'false'); }
        if (!action.payload.head) { localStorage.setItem('loadingTail', 'false'); }
        localStorage.setItem('lastUpdate', (action.payload.head) ? 'head' : 'tail');
        state.error = null;
      } else {
        if (localStorage.getItem('loadingHead') === 'true') { localStorage.setItem('loadingHead', 'false') }
        if (localStorage.getItem('loadingTail') === 'true') { localStorage.setItem('loadingTail', 'false') }
        localStorage.setItem('lastUpdate', (action.payload.head) ? 'head' : 'tail');
        state.error = response;
      }

    }).addCase(fetchWeek.rejected, (state, action) => {
      if (localStorage.getItem('loadingHead') === true) { localStorage.setItem('loadingHead', 'false') }
      if (localStorage.getItem('loadingTail') === true) { localStorage.setItem('loadingTail', 'false') }
      state.error = action.error.message;
    })
  },
});

export const fetchWeek = createAsyncThunk("monthView/fetchWeek",
  async ({ locationId, startDateString, endDateString, head }, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await getEmployeesSchedule(locationId, startDateString, endDateString);
    return { response, head, startDateString }
  }
);

export const reloadMonth = createAsyncThunk("monthView/reloadMonth", async({},{dispatch, getState}) => {
  let state = getState();
})

export const fetchCurrentMonthView = createAsyncThunk(
  "monthView/fetchCurrentMonthView",
  async ({ monthId, locationId, year, userRegisterCtx }, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await getMonthViewData(monthId, locationId, year);
    if (response.load && response.responseCode === 200) {
      dispatch(setLoading(false));
      try {
        const { initialDate, groups, transfers } = response.load;
        const firstGroup = groups[0];
        let shiftLength = 0;
        if (firstGroup && firstGroup.employees.length > 0) {
          const firstEmployee = firstGroup.employees[0];
          shiftLength = firstEmployee.employee.shifts.length;
        }
        const formattedDateString = transformDateTimeZone(
          initialDate,
          ""
        ).format("YYYY-MM-DD");
        const formattedMonthDays = getWeekMonthDays(
          formattedDateString,
          shiftLength
        );
        dispatch(setFormattedMonthDays(formattedMonthDays));
        return transfers.length > 0 ?
          [{ id: 'transfer', groupName: "Users Transfers", employees: transfers }, ...groups] :
          groups;
      } catch (err) {
        console.log(err.message);
      }
    } else if (response.status === 403) {
      dispatch(setLoading(false));
      // userRegisterCtx.resetData();
      dispatch(restartPermissions());
      dispatch(logOut());
      window.location.href = 'login';
    } else {
      dispatch(setLoading(false));
      dispatch(setShowErrorPage(true));
    }
  }
);

export const {
  closeMonthView,
  setFormattedMonthDays,
  deleteShiftUpdateCurrentData,
  addNewShiftUpdateCurrentData,
} = monthViewslice.actions;

export const formattedMonthDaysState = (state) =>
  state.monthView.formattedMonthDays;
export const currentMonthDataState = (state) =>
  state.monthView.currentMonthData
export const monthViewErrorState = (state) =>
  state.monthView.error;

export default monthViewslice.reducer;
