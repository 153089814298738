import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";
export const getToken = () => Cookies.get("token");

export async function getResourceActions(resourceKey) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("resourceKey", resourceKey);
  const url = API_BASE_URL + "/get-resource-actions?" + params.toString();
  if (!token) {
  } else {
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return { resourceKey: resourceKey, permissions: data.load ?? [] };
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}