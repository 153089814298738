import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import classes from './InvalidLogin.module.scss';

const InvalidLogin = ({ onClose }) => {
    return (
        <div id="invalidLoginDialog">
            <Dialog
                open={true}
                onClose={onClose}
                TransitionProps={{
                    timeout: 1000,
                    transitiontimingfunction: 'ease',
                }}
                PaperProps={{
                    style: {
                        width: '400px',
                        height: '280px',
                        transition: 'opacity 0.5s ease', // Add transition CSS property
                        opacity: '1', // Initial opacity
                    },
                }}
                BackdropProps={{
                    style: {
                        transition: 'opacity 0.5s ease', // Add transition CSS property
                    },
                }}
            >
                <DialogTitle
                    style={{
                        padding: 0,
                    }}>
                    <div className={classes.imgErrorContainer}>
                        <span className={`icon-error ${classes.errorIcon}`}></span>
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: 0,
                    }}>
                    <div className={classes.invalidTextContainer}>
                        <div className={classes.invalidTextBold}>Invalid Credentials</div>
                        <div className={classes.invalidText}>The supplied credentials do not match.</div>
                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <button onClick={onClose} className={classes.okButton} >Ok</button>
                </DialogActions>
            </Dialog>
        </div>

    );
};

export default InvalidLogin;
