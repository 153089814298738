import { useCallback, useEffect } from "react";
import { useState } from "react"
import { getScheduleTimes } from "../services/userRegisterService";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";
import { alphabetSort } from "../../utils/utils";

const useSchedules = () => {
  const [schedules, setSchedules] = useState([]);
  const dispatch = useDispatch();

  const loadSchedules = async () => {
    try {
      let response = await getScheduleTimes();
      if (response.responseCode === 200){
        setSchedules(alphabetSort({array: response.load, properties:['label']}));
      } else {
        throw Error('An error occured while retrieving the schedules.')
      }
    } catch (error) {
      console.log(error);
      dispatch(showToast({type: 'warning', message: 'An error occurred while retrieving the schedules.'}))
    }
  }

  useEffect(() => {
    loadSchedules();
  }, [dispatch, setSchedules])

  return [schedules, loadSchedules];

}

export default useSchedules;