import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  getShiftPatterns,
  saveShiftPattern,
  applyPattern,
  deleteShiftPattern,
  getPatternApplied,
  approvePattern,
  deleteAppliedPattern
} from "../components/services/shiftPatternService";
import { setLoading } from "./loadingSlice";
import { showToast } from "./toastNotificationSlice";

export const shiftPatternSlice = createSlice({
  name: "shiftPattern",
  initialState: {
    dayCardCounter: 0,
    dayCards: [],
    allEmployees: [],
    selectedEmployees: [],
    shiftPatterns: [],
    selectedPattern: null,
    showDialog: { state: false, message: "", code: -1 },
    status: "idle",
    error: null,
    appliedPatterns: [],
    patternId: null
  },
  reducers: {
    setInitialStateEmployees: (state, action) => {
      const employeesData = ([] = action.payload);
      const updatedEmployeesData = employeesData.map((group) => ({
        ...group,
        employees: group.employees.map((employee) => ({
          ...employee.employee,
          selected: false,
        })),
      }));
      state.allEmployees = updatedEmployeesData;
      state.selectedEmployees = [];
    },
    selectEmployeeHandler: (state, action) => {
      const data = action.payload;
      const updatedEmployeesData = state.allEmployees.map((group) => ({
        ...group,
        employees: group.employees.map((employee) => {
          if (employee.id === data.id) {
            return {
              ...employee,
              selected: !employee.selected,
            };
          } else {
            return employee;
          }
        }),
      }));
      state.allEmployees = updatedEmployeesData;
      if (data.selected === false) {
        state.selectedEmployees.push(data);
      } else {
        const updatedSelectedEmployees = state.selectedEmployees.filter(
          (item) => {
            return data.id !== item.id;
          }
        );
        state.selectedEmployees = updatedSelectedEmployees;
      }
    },
    addDayCard: (state, action) => {
      state.dayCards.push(action.payload);
      state.dayCardCounter = state.dayCardCounter + 1;
    },
    deleteDayCard: (state) => {
      state.dayCards.pop();
      if (state.dayCardCounter > 0) {
        state.dayCardCounter = state.dayCardCounter - 1;
      }
    },
    selectedDayHandler: (state, action) => {
      const dayData = action.payload;
      const updateDayCards = state.dayCards.map((item) => {
        if (item.day === dayData.day) {
          return { ...item, status: !dayData.status, site: dayData.site, scheduleTime:dayData.scheduleTime };
        }
        return item;
      });
      state.dayCards = updateDayCards;
    },
    addEmployee: (state, action) => {
      const employee = action.payload;
      const updateEmployee = state.dayCards.map((item) => {
        if (item.id === employee.id) {
          const selected = employee.selected ? !employee.selected : true;
          return { ...item, selected: selected };
        }
        return item;
      });
      state.allEmployees = updateEmployee;
    },
    selectedPatternHandler: (state, action) => {
      state.selectedPattern = action.payload;
    },
    updateDayCardSite: (state, action) => {
      const { cardId, site, scheduleTime } = action.payload;
      const updateSite = state.dayCards.map((item) => {
        if (item.day === cardId) {
          return { ...item, site: site, scheduleTime:scheduleTime };
        }
        return item;
      });
      state.dayCards = updateSite;
    },
    updatedDayCardSchedule: (state, action) => {
      const { cardId, schedule } = action.payload;
      const updateSchedule = state.dayCards.map((item) => {
        if (item.day === cardId) {
          return { ...item, scheduleTime: schedule };
        }
        return item;
      });
      state.dayCards = updateSchedule;
    },
    setDayCardValue: (state, action) => {
      const { arrayData, length } = action.payload;
      state.dayCards = arrayData;
      state.dayCardCounter = length;
    },
    clearShiftPatternComponent: (state, action) => {
			let location = action.payload;
			let defaultConfig = location?.scheduleTimes?.[0] ?? {scheduleTime: null, site: null};
      state.selectedPattern = null;
			state.dayCards = Array.from({length: 7}).map((_, day) => ({
				day,
				site:defaultConfig.site, 
				scheduleTime: defaultConfig.scheduleTime,
				status:false
			}))
			state.dayCardCounter = 7;
      // state.dayCards = state.dayCards.map(d => ({...d, status: false})).slice(0,);
      // state.dayCardCounter = 0;
    },
    showDialogHandler: (state, action) => {
      state.showDialog = action.payload;
    },
    enableAppliedPattern: (state, action) => {
      state.appliedPatterns = state.appliedPatterns.map(
        pattern => {
          if (pattern.id === action.payload.patternId) {
            pattern.aproved = action.payload.newValue
          }
          return pattern;
        }
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftPatterns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShiftPatterns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.shiftPatterns = action.payload;
      })
      .addCase(fetchShiftPatterns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(loadAppliedPatterns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadAppliedPatterns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.appliedPatterns = action.payload.load.map(pattern => ({
          ...pattern, employees: pattern.employees.sort((a, b) => a.id - b.id)
        }))
        state.patternId = action.payload.patternId;
      })
      .addCase(loadAppliedPatterns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

  },
});

export const fetchShiftPatterns = createAsyncThunk(
  "shiftPattern/fetchShiftPatterns",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await getShiftPatterns();
    if (response?.load) {
      dispatch(setLoading(false));
      return response.load;
    } else {
      dispatch(setLoading(false));
      return [];
    }
  }
);

export const approveAppliedPatterns = createAsyncThunk(
  "shiftPattern/approveAppliedPattern",
  async (enabledIds, { dispatch, getState }) => {
    const currentPattern = getState().shiftPattern.patternId;
    let success = [];
    if (enabledIds.length > 0) {
      dispatch(showToast({ type: 'info', message: 'Approving shifts...' }))
    }
    for (let id of enabledIds) {
      const response = await approvePattern(id);
      success.push(response?.responseCode === 200);
      if (response?.responseCode !== 200) {
        console.log('error', response);
      }
    }
    if (enabledIds.length > 0) {
      if (success.every(s => s === true)) {
        dispatch(showToast({ type: 'success', message: 'Shifts successfully approved' }))
      } else {
        dispatch(showToast({ type: 'warning', message: 'There was an issue during shifts approval' }))
      }
    }
    dispatch(loadAppliedPatterns(getState().shiftPattern.patternId));
  }
);

export const saveUpdateShiftPattern = createAsyncThunk(
  "shiftPattern/saveShiftPattern",
  async (data, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await saveShiftPattern(data);
    if (response?.responseCode === 200) {
      dispatch(setLoading(false));
      dispatch(showToast({ type: 'success', message: 'Pattern saved' }));
    } else {
      dispatch(setLoading(false));
      dispatch(showToast({ type: 'warning', message: 'Something went wrong while saving the pattern' }))
    }
    if (response?.load) {
      dispatch(fetchShiftPatterns());
      dispatch(selectedPatternHandler(response.load));
      dispatch(loadAppliedPatterns(response.load.id));
    }
  }
);

export const removeAppliedPattern = createAsyncThunk(
  "shiftPattern/removeAppliedPattern",
  async (data, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await deleteAppliedPattern(data.id);
    if (response) {
      if (response.responseCode === 200) {
        dispatch(setLoading(false));
        dispatch(
          showDialogHandler({
            state: true,
            message: "Pattern removed Successfully",
            code: 200,
          })
        );
      } else {
        dispatch(setLoading(false));
        dispatch(
          showDialogHandler({
            state: true,
            message: response.message,
            code: response.responseCode,
          })
        );
      }
      dispatch(loadAppliedPatterns(data.shiftPatternId));
    } else {
      dispatch(setLoading(false));
      dispatch(showToast({ type: 'warning', message: 'Something went wrong while removing the pattern', duration: 4000 }))
    }
  }
);

export const applyShiftPattern = createAsyncThunk(
  "shiftPattern/applyShiftPattern",
  async (data, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await applyPattern({...data, employees: data.employees.map(employee => ({id:employee.id, userName: employee.userName}))});
    if (response) {
      if (response.responseCode === 200) {
        dispatch(setLoading(false));
        dispatch(loadAppliedPatterns(data.shiftPatternId));
        dispatch(
          showDialogHandler({
            state: true,
            message: "Pattern applied Successfully",
            code: 200,
          })
        );
      } else {
        dispatch(setLoading(false));
        dispatch(
          showDialogHandler({
            state: true,
            message: response.message,
            code: response.responseCode,
          })
        );
      }
    } else {
      dispatch(setLoading(false));
      dispatch(showToast({ type: 'warning', message: 'Something went wrong while applying the pattern', duration: 4000 }))
    }
  }
);

export const deleteShiftPatternAction = createAsyncThunk(
  "shiftPattern/deleteShiftPatternAction",
  async (data, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await deleteShiftPattern(data);
    if (response?.responseCode === 200) {
      dispatch(setLoading(false));
      dispatch(fetchShiftPatterns());
      dispatch(showToast({ type: 'info', message: 'Pattern deleted'}))
    } else {
      dispatch(setLoading(false));
      dispatch(showToast({ type: 'warning', message: 'Something went wrong while deleting the pattern', duration: 4000 }))
    }
  }
);

export const loadAppliedPatterns = createAsyncThunk(
  "shiftPattern/loadAppliedPatterns",
  async (patternId, { dispatch }) => {
    const response = await getPatternApplied(patternId);
    if (response?.responseCode === 200) {
      return { ...response, patternId: patternId };
    } else {
      console.log( 'Something went wrong while retrieving applied patterns');
    }
  }
);

export const {
  addDayCard,
  deleteDayCard,
  selectedDayHandler,
  setInitialStateEmployees,
  selectEmployeeHandler,
  selectedPatternHandler,
  updateDayCardSite,
  updatedDayCardSchedule,
  setDayCardValue,
  clearShiftPatternComponent,
  showDialogHandler,
  enableAppliedPattern
} = shiftPatternSlice.actions;

export const dayCards = (state) => state.shiftPattern.dayCards;
export const dayCardCounter = (state) => state.shiftPattern.dayCardCounter;
export const employeesState = (state) => state.shiftPattern.allEmployees;
export const selectedEmployees = (state) =>
  state.shiftPattern.selectedEmployees;
export const shiftPatternList = (state) => state.shiftPattern.shiftPatterns;
export const selectedPatternState = (state) =>
  state.shiftPattern.selectedPattern;
export const appliedPatternState = (state) => state.shiftPattern.appliedPatterns;
export const showDialogState = (state) => state.shiftPattern.showDialog;

export default shiftPatternSlice.reducer;
