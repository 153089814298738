import React from "react";

const UserRegisterContext = React.createContext({
  selectedLocation: [],
  loadedLocations: [],
  selectedYear: {},
  selectedMonth: {},
  selectedWeek: {
    status: "",
    masterId: "",
    startDate: new Date(),
    endDate: new Date(),
    monthString: "",
    dayString: "",
    week: [],
    shortNameMonth: ""
  },
  allocatedShiftsData: {},
  scheduleTimes: [],
  currentWeekData: [],
  transferUsers: [],
  changeWeek: (date) => {},
  changeMonth: (month) => {},
  changeLocation: (location) => {},
  setInitialLocations: (location) => {},
  changeYear: (year) => {},
  fetchWeekData: (location,startDate,endDate) => {},
  resetData: () => {},
  fetchWeekDataFromYearMonth: (location, yearObj, monthId, pageId) => {},
  fetchScheduleTimes: () => {}
});

export default UserRegisterContext;
