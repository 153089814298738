import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedFiltersState } from "../../features/filterSlice";

const useFilteredUserGroups = (userList) => {
  const selectedFilters = useSelector(selectedFiltersState);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredUserGroups, setFilteredUserGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployeesBySearch, setFilteredEmployeesBySearch] = useState(
    []
  );
  const [filterUsers, setFilterUsers] = useState([]);

  const handleFilterToggle = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const applySearch = useCallback(() => {
    const query = searchQuery.toLowerCase();
    const filterUserBySearch = [];
    filteredUserGroups?.forEach((userGroup) => {
      const filterEmployees = userGroup.employees.filter((employee) => {
        const fullName =
          `${employee.employee.userName}`.toLowerCase();
        return fullName.includes(query);
      });
      if (filterEmployees.length > 0) {
        const filterGroup = {
          ...userGroup,
          employees: filterEmployees,
        };
        filterUserBySearch.push(filterGroup);
      }
    });
    setFilteredEmployeesBySearch(filterUserBySearch);
  }, [filteredUserGroups, searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  };

  useEffect(()=>{
    applySearch();
  }, [searchQuery, applySearch]);

  useEffect(()=>{
    applySearch();
  }, [userList, filteredUserGroups, applySearch]);

  useEffect(() => {
    let filteredGroups = [];
    if (
      selectedFilters[0]?.filterIds?.length > 0 ||
      selectedFilters[1]?.filterIds?.length > 0 ||
      selectedFilters[2]?.filterIds?.length > 0
    ) {
      // Filter the user groups based on the selected filters
      userList.forEach((userGroup) => {
        if (
          selectedFilters[2]?.filterIds.includes(userGroup.id) ||
          selectedFilters[2].filterIds.length === 0
        ) {
          const filteredEmployees = userGroup?.employees?.filter((employee) => {
            //filter ONLY by group  
            if (selectedFilters[0]?.filterIds?.length === 0 &&
              selectedFilters[1]?.filterIds?.length === 0 &&
              selectedFilters[2]?.filterIds.includes(userGroup.id)) {
                return true;
            } else {
            // Filter the employee skills based on the selected filters
            const filteredSkills = employee?.employee?.skills?.filter(
              (skill) => {
                const isAircraftTypeInclude =
                  selectedFilters[0]?.filterIds?.includes(skill.aircraftTypeId);
                const isEndorsementInclude =
                  selectedFilters[1]?.filterIds?.includes(skill.endorsementId);
                if (
                  (isAircraftTypeInclude === true &&
                    isEndorsementInclude === true) ||
                  (selectedFilters[0]?.filterIds?.length === 0 &&
                    selectedFilters[1]?.filterIds?.length === 0 &&
                    selectedFilters[2]?.filterIds.includes(userGroup.id))
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            );
             // Include the employee in the filtered list if they have at least one matching skill
             return filteredSkills?.length > 0 ? true : false;
            }
          });
          if (filteredEmployees.length > 0) {
            filteredGroups.push({
              ...userGroup,
              employees: filteredEmployees,
            });
          }
        }
      });
    } else {
      filteredGroups = userList;
    }
    // Update the filtered user groups state
    setFilteredUserGroups(filteredGroups);
  }, [selectedFilters, userList]);

  useEffect(()=>{
    setFilterUsers(searchQuery.length > 0 ? filteredEmployeesBySearch : filteredUserGroups);
  }, [filteredEmployeesBySearch, filteredUserGroups, setFilterUsers, searchQuery]);
  
  return {
    isFilterOpen,
    filterUsers,
    searchQuery,
    filteredUserGroups,
    filteredEmployeesBySearch,
    setSearchQuery,
    handleFilterToggle,
    handleSearchChange,
  };
};

export default useFilteredUserGroups;
