import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { WEEK, indexBy, objectMap } from "../utils/utils";
import { getRequiredShifts, saveSiteRequiredShifts } from "../components/services/requiredShiftsService";
import { showToast } from "./toastNotificationSlice";

/**
 * {1: {1:{requiredShifts: x, weekDays:[{requiredShifts}]},2:,3:}}
 * 
 */
const requiredShiftsSlice = createSlice({
  name: 'requiredShifts',
  initialState: {
  },
  reducers: {
    updateScheduleRequired(state, action) {
      const { site, scheduleTime, newValue } = action.payload;
      return {
        ...state,
        [site.id]: {
          ...state[site.id] ?? {},
          [scheduleTime.id]: {
            ...state[site.id]?.[scheduleTime.id] ?? {},
            requiredShifts: newValue,
            site,
            scheduleTime,
          }
        }
      }
    },
    updateDayRequired: (state, action) => {
      const { site, scheduleTime, dayIdx, newValue } = action.payload;
      return {
        ...state,
        [site.id]: {
          ...state[site.id] ?? {},
          [scheduleTime.id]: {
            ...state[site.id]?.[scheduleTime.id] ?? {},
            weekDays: (state[site.id]?.[scheduleTime.id]?.weekDays ?? WEEK.map(d => ({ requiredShifts: '' }))).map((day, idx) => ({
              ...day,
              requiredShifts: idx === dayIdx ? newValue : (day.requiredShifts ?? '')
            })),
            site,
            scheduleTime,
          }
        }
      }
    },
    cleanSite: (state, action) => {
      const { siteId } = action.payload;
      return {
        ...state,
        [siteId]: objectMap({ ...state[siteId] ?? {} }, schedule => ({
          ...schedule,
          requiredShifts: '',
          weekDays: (schedule.weekDays?.map(d => ({ ...d, requiredShifts: '' })) ?? WEEK.map(d => ({ requiredShifts: '' })))
        }))
      }
    },
    cleanAllSites: (state, action) => {
      return objectMap({ ...state }, site => objectMap({ ...site ?? {} }, schedule => ({
        ...schedule,
        requiredShifts: '',
        weekDays: (schedule.weekDays?.map(d => ({ ...d, requiredShifts: '' })) ?? WEEK.map(d => ({ requiredShifts: '' })))
      })))
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequiredShifts.fulfilled, (state, action) => {
      return {...action.payload} ?? {};
    });
  }
});

export const fetchRequiredShifts = createAsyncThunk('requiredShifts/fetchRequiredShifts',
  async ({ locationId, locationSites }, { dispatch }) => {
    const requests = locationSites.flatMap(site => site.schedules.map(scheduleId => getRequiredShifts(locationId, site.id, scheduleId)));
    const responses = await Promise.all(requests);
    if (responses.every(response => response.success)) {
      return responses.reduce((obj, item) => ({
        ...obj, [item.siteId]: {
          ...obj[item.siteId] ?? {}, [item.scheduleId]: item.load ?? {
            requiredShifts: '',
            weekDays: WEEK.map(d => ({ requiredShifts: '' }))
          }
        }
      }), {});
    } else {
      dispatch(showToast({ type: 'warning', message: "Something went wrong while retrieving required shifts" }));
      return null;
    }
  }
)

export const saveRequiredShifts = createAsyncThunk('requiredShifts/saveRequiredShifts',
  async ({ locationId }, { dispatch, getState }) => {
    const currentInfo = getState().requiredShifts;
    const body = Object.values(currentInfo).flatMap(
      (site) => Object.values(site).filter(schedule => schedule.site && schedule.scheduleTime)
        .map(schedule => ({ 
          ...schedule, 
          requiredShifts: schedule.requiredShifts || 0,
          weekDays: schedule.weekDays.map((d, i) => ({ 
            ...d, 
            weekDay: d.weekDay ?? i + 1, 
            requiredShifts: d.requiredShifts || 0 
          })),
          locationId,
        }))
    );
    const response = await saveSiteRequiredShifts(locationId, body);
    if (response.success) {
      dispatch(showToast({type: 'success', message: 'Required shifts updated'}))
    }
    
  }
)

export default requiredShiftsSlice.reducer;
export const { updateDayRequired, updateScheduleRequired, cleanSite, cleanAllSites } = requiredShiftsSlice.actions;
export const selectRequiredShifts = (state) => state.requiredShifts;
export const selectSiteRequiredShifts = (siteId) => (state) => state.requiredShifts[siteId];