import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function getAirCraftType() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/get-aircraft-types`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Login failed");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export async function getEndoresment() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/get-endorsements`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Login failed");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export async function getOpenCategoriesCrewOptions(locationId) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(
      `${API_BASE_URL}/get-employee-groups?locationId=${locationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Login failed");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

function setEndorsements(endorsements) {
  const updatedEndorsements = endorsements.map((categoryEndorsement) => ({
    ...categoryEndorsement,
    selected: false,
  }));
  return updatedEndorsements;
}

export async function getOpenCategoriesInitialState(locationId) {
  const aircraftResponse = await getAirCraftType();
  const crewResponse = await getOpenCategoriesCrewOptions(locationId);
  const options = [
    { id: 1, categories: [], filter: null },
    { id: 2, categories: [], filter: null },
    { id: 3, categories: [], filter: null },
  ];
  if (aircraftResponse) {
    const updatedLoad = aircraftResponse.load.map((category) => ({
      ...category,
      selected: false,
      endorsements: setEndorsements(category.endorsements),
    }));
    options[0].categories = updatedLoad;
  }
  if (crewResponse) {
    const updatedCrew = crewResponse.load.map((crew) => ({
      ...crew,
      selected: false,
    }));
    options[2].categories = updatedCrew;
  }
  return options;
}