import React, { useState } from 'react';
import AddComments from "./AddComments";
import classes from "./UserRegister.module.scss";
import Tooltip from '@mui/material/Tooltip';
import { defaultRoleColor, getContrastColor } from '../../utils/utils';

const patternShiftStyle = {
  backgroundColor: "#e5e5e5",
  border: "solid 1px #6d6d6d",
  color: "#6d6d6d",
};

const shiftStyle = (register) => (
  register.status === false ?
    patternShiftStyle
    :
    {
      backgroundColor: register.scheduleTime.color ?? defaultRoleColor,
      border: `2px solid ${register.scheduleTime.color}`,
      color: getContrastColor(register.scheduleTime.color ?? defaultRoleColor)
    }
);
const UserRegister = ({ register, monthView }) => {

  const [hideTooltip, setHideTooltip] = useState(false);
  const onClose = () => {
    setHideTooltip(false);
  }

  const commentsClick = (event) => {
    event.stopPropagation();
    setHideTooltip(true);
  }

  return (
    <>
      {monthView ? (
        <Tooltip
          title={<div style={{ fontSize: "12px" }}>{register.scheduleTime.label + ' ' + register.site.name}</div>}
          placement="bottom"
          arrow
          hidden={hideTooltip}
          PopperProps={{
            popperOptions: {
              modifiers: {
                offset: {
                  enabled: true,
                  offset: '-10px, 0px', // Adjust the offset values as per your requirement
                },
              },
            },
          }}
        >
          <div
            className={monthView ? classes.registerMonth : classes.register}
            style={shiftStyle(register)}
          >
            {monthView && (
              <>
                <div className={classes.monthViewDayContainer}>
                  <div className={`${classes.monthName}`}>
                    <div onClick={commentsClick}>
                      <AddComments shiftId={register.id} onClose={onClose} iconButton={true} hideWhenEmpty={true} style={{ height: '0.7em', fontSize: '0.9em' }} />
                    </div>
                    {register.overtime === true && (
                      <span className={`icon-overtime ${classes.iconOvertime}`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </span>
                    )}

                    {(register.specialInitialTime || register.specialFinalTime)
                      && <span className='icon-time-changed'></span>
                    }
                    <i className={`icon-check ${classes.iconCheck}`} />
                    <span className={classes.monthShortName}>{register.shortName}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </Tooltip>) :
        (<div
          className={monthView ? classes.registerMonth : classes.register}
          style={shiftStyle(register)}
        >
          {!monthView && (
            <>
              <div className={classes.shiftName}>
                <div className={`${classes.name} ${classes.weekName}`}>
                  <i className={`icon-check ${classes.iconCheck}`} />
                  <span className={classes.shortName}>{register.shortName}</span>
                  {(register.specialInitialTime || register.specialFinalTime)
                    && <span className='icon-time-changed'></span>
                  }
                  {register.overtime === true && (
                    <span className="icon-overtime">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  )}

                  <div onClick={commentsClick}>
                    {/* week-view comments */}
                    <AddComments shiftId={register.id} iconButton={true} hideWhenEmpty={true} onClose={onClose} showBadge={true} />
                  </div>

                </div>
                <div className={classes.nameDescription}>{`${register.scheduleTime.label} ${register.site.name}`}</div>
              </div>
            </>
          )}
        </div>
        )
      }
    </>
  );
};

export default UserRegister;
