import React from "react";
import Modal from "../../components/UI/Modal";
import classes from "./EmployeeInfo.module.scss";
import { UserInformationDetail } from "../Users/UserInformationDetail";
import { EmployeePermissionsContainer } from "./EmployeePermissionsContainer";

const EmployeeInfo = ({ onClose, employee }) => {
  return (
    <Modal className={"employeeInfoContainer"} onClose={onClose} id={"employeeInfoDialog"}>
      <div className={classes.headerContainer}>
        <span className={`icon-permission ${classes.permissionIcon}`}></span>
        <span className={classes.headerTitle}>EMPLOYEE INFO</span>
        <button className={classes.doneBtn} onClick={onClose}>Done</button>
        <button className={classes.closeModal} onClick={onClose}>
          <span className={`icon-close ${classes.icon}`}></span>
        </button>
      </div>
      <UserInformationDetail employee={employee}></UserInformationDetail>
      <EmployeePermissionsContainer employeeId={employee.id}></EmployeePermissionsContainer>
    </Modal>
  );
};

export { EmployeeInfo };
