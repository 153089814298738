import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import classes from "./HamburgerMenu.module.scss";
import {
  logOut,
  resources,
  handlerViewPageChange,
  logout,
} from "../../features/userSlice";
import { restartPermissions } from "../../features/resourcePepermissionsSlice";
import {
  HOME_ID,
  MONTHVIEW_ID,
  SHIFTPATTERN_ID,
  USER_ADMINISTRATION_ID,
  ROLECREATION_ID,
  HOME,
  MONTH_VIEW,
  SHIFT_PATTERN,
  USER_ADMINISTRATION,
  ROLE_CREATIOIN,
  LOGIN,
  ADMIN_LOCATIONS,
  ADMIN_LOCATIONS_ID,
  ADMIN_LEAVES,
  ADMIN_LEAVES_ID,
  CREW_CONFIGURATION,
  CREW_CONFIGURATION_ID,
} from "../services/global";
import { isMobile } from "../services/global";
import { addUserToCosmosDB } from "../../features/cosmosdbSlice";
import {
  updatecurrentPageId,
  updateCurrentLocationId,
  updatecurrentYearId,
  updatecurrentMonthId,
  cleanCurrentData,
  currentLocationIdState,
  currentMonthIdState,
  currentYearIdState,
  selectedLocationState,
  selectedMonthState,
  selectedYearState,
} from "../../features/currentDataSlice";
import UserRegisterContext from "../store/user-register-context";
import { fetchCurrentMonthView } from "../../features/monthViewSlice";
import ClickOutsideComponent from "../UI/ClickOutsideComponent";
import { useMsal } from "@azure/msal-react";
import { logoutRequest, logoutRquest } from "../../config/MsAuthConfig";
import { EventType } from "@azure/msal-browser";
import { loadYearMonth } from "../services/dateTimeService";

const keepStorageFor = ['lastViewed', 'lastLocation'];

function HamburgerMenu() {
  const userRegisterCtx = useContext(UserRegisterContext);
  const [isOpen, setIsOpen] = useState(false);
  const allowedResources = useSelector(resources);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //GET VARIABLES FROM SLICE
  const locationId = useSelector(currentLocationIdState);
  const monthId = useSelector(currentMonthIdState);
  const year = useSelector(currentYearIdState);
  const selectedLocation = useSelector(selectedLocationState);
  const selectedMonth = useSelector(selectedMonthState);
  const selectedYear = useSelector(selectedYearState);

  const mobile = window.innerWidth < isMobile;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const setUserPage = (page, pageName) => {
    dispatch(handlerViewPageChange(page));
    dispatch(updatecurrentPageId(page));
    if (page === 3 || page === 4 || page === 5 || page === 6) {
      dispatch(updateCurrentLocationId(0));
      dispatch(updatecurrentMonthId(0));
      dispatch(updatecurrentYearId(0));
    }
    //save currentPage in localStorage
    localStorage.setItem("currentPath", pageName);
  };

  const openHomeHandler = (e) => {
    e.preventDefault();
    setUserPage(HOME_ID, HOME);
    setIsOpen(false);
    navigate(HOME);

    if (selectedLocation && selectedMonth && selectedYear) {
      dispatch(updateCurrentLocationId(selectedLocation.id));
      dispatch(updatecurrentMonthId(selectedMonth.id));
      dispatch(updatecurrentYearId(selectedYear.id));
      const [year, month, startDate, endDate] = loadYearMonth();
      userRegisterCtx.fetchWeekData(selectedLocation, startDate, endDate, year, month, HOME_ID);
    }
  };

  const openMonthViewHandler = async (e) => {
    e.preventDefault();
    setUserPage(MONTHVIEW_ID, MONTH_VIEW);
    setIsOpen(false);
    navigate(MONTH_VIEW);

    if (selectedLocation && selectedMonth && selectedYear) {
      dispatch(updateCurrentLocationId(selectedLocation.id));
      dispatch(updatecurrentMonthId(selectedMonth.id));
      dispatch(updatecurrentYearId(selectedYear.id));

      const yearFormatted = parseInt(selectedYear.label, 10);
      dispatch(
        fetchCurrentMonthView({
          monthId: selectedMonth.id,
          locationId: selectedLocation.id,
          year: yearFormatted,
        })
      );

      dispatch(
        addUserToCosmosDB({
          pageId: MONTHVIEW_ID,
          locationId,
          weekId: 0,
          monthId,
          year,
        })
      );
    }
  };

  const showShiftPatternToggle = (e) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(SHIFT_PATTERN);
    setUserPage(SHIFTPATTERN_ID, SHIFT_PATTERN);
    dispatch(
      addUserToCosmosDB({
        pageId: SHIFTPATTERN_ID,
        locationId,
        weekId: 0,
        monthId,
        year,
      })
    );
  };

  const openUserAdministration = (e) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(USER_ADMINISTRATION);
    setUserPage(USER_ADMINISTRATION_ID, USER_ADMINISTRATION);
    dispatch(
      addUserToCosmosDB({
        pageId: USER_ADMINISTRATION_ID,
        locationId: 0,
        weekId: 0,
        monthId: 0,
        year: 0,
      })
    );
  };

  const openRoleManagement = (e) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(ROLE_CREATIOIN);
    setUserPage(ROLECREATION_ID, ROLE_CREATIOIN);
    dispatch(
      addUserToCosmosDB({
        pageId: ROLECREATION_ID,
        locationId: 0,
        weekId: 0,
        monthId: 0,
        year: 0,
      })
    );
  };

  const openAdminLocations = (e) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(ADMIN_LOCATIONS);
    setUserPage(ADMIN_LOCATIONS_ID, ADMIN_LOCATIONS);
    dispatch(
      addUserToCosmosDB({
        pageId: ADMIN_LOCATIONS_ID,
        locationId: 0,
        weekId: 0,
        monthId: 0,
        year: 0,
      })
    );
  };

  const openAdminLeaves = (e) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(ADMIN_LEAVES);
    setUserPage(ADMIN_LEAVES_ID, ADMIN_LEAVES);
    dispatch(
      addUserToCosmosDB({
        pageId: ADMIN_LEAVES_ID,
        locationId: 0,
        weekId: 0,
        monthId: 0,
        year: 0,
      })
    );
  };

  const openCrewConfiguration = (e) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(CREW_CONFIGURATION);
    setUserPage(CREW_CONFIGURATION_ID, CREW_CONFIGURATION);
    dispatch(
      addUserToCosmosDB({
        pageId: CREW_CONFIGURATION_ID,
        locationId: 0,
        weekId: 0,
        monthId: 0,
        year: 0,
      })
    );
  };

  const { instance } = useMsal();

  const microsoftLogout = () => {
    return instance.logoutPopup(logoutRequest);
  }

  const logOutClick = async (e) => {
    handleLogOut();
    microsoftLogout();
    navigate(LOGIN);
  }

  const handleLogOut = (e) => {
    e?.preventDefault();
    dispatch(logout());
    dispatch(logOut());
    dispatch(restartPermissions());
    userRegisterCtx.resetData();
    setIsOpen(false);
    dispatch(cleanCurrentData());
    for (let i = 0; i < localStorage.length; i++) {
      if (!keepStorageFor.includes(localStorage.key(i))) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
  };

  const clickOutsideMenu = () => {
    if (isOpen) setIsOpen(false);
  }

  return (
    <div className={classes.hamburgerMenu}>
      <ClickOutsideComponent handleClickOutside={clickOutsideMenu}>
        <div
          className={`${classes.menuBtn} ${isOpen ? classes.open : ""}`}
          onClick={toggleMenu}
          id="iconMenuButton"
        >
          <span
            className={isOpen ? `icon-close ${classes.iconClose}` : "icon-menu"}
          />
        </div>
        <nav className={`${classes.menu} ${isOpen ? classes.open : ""}`}>
          <ul>
            <li onClick={(e) => openHomeHandler(e)}>
              <Link to={HOME}>Home</Link>
            </li>
            {allowedResources.some((resource) => resource.key === "month-view") &&
              !mobile && (
                <li onClick={(e) => openMonthViewHandler(e)} id="monthViewOption">
                  <Link to={MONTH_VIEW}>Month View</Link>
                </li>
              )}
            {allowedResources.some(
              (resource) => resource.key === "pattern"
            ) &&
              !mobile && (
                <li
                  onClick={(e) => showShiftPatternToggle(e)}
                  id="shiftPatternOption"
                >
                  <Link to={SHIFT_PATTERN}>Shift Pattern</Link>
                </li>
              )}
            {allowedResources.some(
              (resource) => resource.key === "user-administration"
            ) &&
              !mobile && (
                <li onClick={(e) => openUserAdministration(e)}>
                  <Link to={USER_ADMINISTRATION}>User Administration</Link>
                </li>
              )}
            {allowedResources.some(
              (resource) => resource.key === "role-administration"
            ) &&
              !mobile && (
                <li onClick={(e) => openRoleManagement(e)}>
                  <Link to={ROLE_CREATIOIN}>Role Creation</Link>
                </li>
              )}

            {allowedResources.some(
              (resource) => resource.key === "admin-locations"
            ) &&
              !mobile && (
                <li onClick={(e) => openAdminLocations(e)}>
                  <Link to={ADMIN_LOCATIONS}>Location Administration</Link>
                </li>
              )}

            {allowedResources.some(
              (resource) => resource.key === "admin-leaves"
            ) &&
              !mobile && (
                <li onClick={(e) => openAdminLeaves(e)}>
                  <Link to={ADMIN_LEAVES}>Leaves Administration</Link>
                </li>
              )}
            {allowedResources.some(
              (resource) => resource.key === "crew-configuration"
            ) &&
              !mobile && (
                <li onClick={(e) => openCrewConfiguration(e)}>
                  <Link to={CREW_CONFIGURATION}>Crew Configuration</Link>
                </li>
              )}

            <li onClick={(e) => logOutClick(e)}>
              <Link to={LOGIN}>Log Out</Link>
            </li>
          </ul>
        </nav>
      </ClickOutsideComponent>
    </div>
  );
}

export default HamburgerMenu;
