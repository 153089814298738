import Cookies from "js-cookie";
import {
  API_BASE_URL,
  API_BASE_URL_LOGIN,
  SUPER_ADMIN_RESOURCES,
  isMobile,
  HEADER_SUBSCRIPTION_KEY,
} from "./global";
export const getToken = () => Cookies.get("token");

export async function saveSiteRequiredShifts(locationId, body) {
  const params = new URLSearchParams();
  params.append('locationId', locationId);
  // params.append('siteId', siteId);
  // params.append('scheduleTimeId', scheduleId);
  const token = getToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/save-required-shifts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body)
      }
    );
    if (response.ok) {
      const data = await response.json();
      if (data.responseCode === 200) {
        return { success: true, load: data.load };
      } else {
        console.log('error', data.message);
        return { success: false, message: data.message }
      }
    } else {
      return { success: false, message: "Something went wrong while saving required shifts" };
    }
  } catch (e) {
    console.log('error',  e);
    return { success: false, message: "Something went wrong while saving required shifts" };
  }
  
}

export async function getRequiredShifts(locationId, siteId, scheduleId) {
  const params = new URLSearchParams();
  params.append('locationId', locationId);
  params.append('siteId', siteId);
  params.append('scheduleTimeId', scheduleId);
  const token = getToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/get-required-shifts?${params.toString()}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    if (response.ok) {
      const data = await response.json();
      if (data.responseCode === 200) {
        return { success: true, load: data.load, scheduleId, siteId };
      } else {
        console.log('error', data.message);
        return { success: false, message: data.message }
      }
    } else {
      return { success: false, message: "Something went wrong while retrieving required shifts" };
    }
  } catch (e) {
    console.log('error',  e);
    return { success: false, message: "Something went wrong while retrieving required shifts" };
  }
  
}