import React, { useState } from "react";
import classes from "./InitialAndFinalDate.module.scss";
import { DatePicker } from "../datepicker/DatePicker";
import { format } from 'date-fns';
import Input from "../UI/Input";
import { useEffect } from "react";

const InitialAndFinalDate = ({ onSaveDateHandler, resetDates, theme="dark" }) => {
  const [startDate, setStartDate] = useState({ date: null, formattedDate: "" });
  const [endDate, setEndDate] = useState({ date: null, formattedDate: "" });
  const [activeStartDate, setActiveStartDate] = useState(false);
  const [activeEndDate, setActiveEndDate] = useState(false);

  useEffect(()=>{
    if(resetDates === true){
      setStartDate({ date: null, formattedDate: "" });
      setEndDate({ date: null, formattedDate: "" });
    }
  }, [resetDates]);

  const toggleActiveStartDate = () => {
    if (activeEndDate === true) {
      setActiveEndDate(false);
    }
    setActiveStartDate(!activeStartDate);
  };

  const toggleActiveEndDate = () => {
    if (activeStartDate === true) {
      setActiveStartDate(false);
    }
    setActiveEndDate(!activeEndDate);
  };

  const onSaveDate = (day) => {
    if (day) {
      const dateTotransform = new Date(day);
      const date = format(dateTotransform, "yyyy-MM-dd");
      const formattedDate = format(dateTotransform, "MM/dd/yyyy");
      const dateObj = {
        date,
        formattedDate,
      };
      if (activeStartDate === true) {
        setStartDate(dateObj);
        onSaveDateHandler(dateObj, 0)
      }
      if (activeEndDate === true) {
        setEndDate(dateObj);
        onSaveDateHandler(dateObj, 1)
      }
    }
    if (activeStartDate === true) {
      setActiveStartDate(false);
    }
    if (activeEndDate === true) {
      setActiveEndDate(false);
    }
  };

  const transformDate = (day) => {
    const dateTotransform = new Date(day);
    const date = format(dateTotransform, "yyyy-MM-dd");
    const formattedDate = format(dateTotransform, "MM/dd/yyyy");
    return {
      date,
      formattedDate,
    };
  };

  const dateChange = (e, idx) => {
    if (e.target.value) {
      const dateObj = transformDate(e.target.value);
      if (idx == 0) {
        setStartDate(dateObj);
      } else {
        setEndDate(dateObj);
      }
      onSaveDateHandler(dateObj, idx)
    }
  }

  return (
    <>
      <div className={classes.inputContainer}>
        <Input theme={theme} onChange={(e) => dateChange(e, 0)} value={startDate?.formattedDate} type="date" icon={'icon-calendar'} iconPosition="right" iconClassName={classes[theme]} label={"Initial Date"} />
        <Input theme={theme} onChange={(e) => dateChange(e, 1)} value={endDate?.formattedDate} type="date" icon={'icon-calendar'} iconPosition="right" iconClassName={classes[theme]} label={"Final Date"} />

      </div>
    </>
  );
};

export default InitialAndFinalDate;