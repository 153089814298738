export const API_BASE_URL = window.BACKEND_URL + '/api/secure';
export const API_BASE_URL_LOGIN = window.BACKEND_URL + "/api";
export const MICROSOFT_APPLICATION_CLIENT_ID = window.MICROSOFT_APPLICATION_CLIENT_ID;
export const MICROSOFT_APPLICATION_TENANT_ID = window.MICROSOFT_APPLICATION_TENANT_ID;
export const HEADER_SUBSCRIPTION_KEY = window.HEADER_SUBSCRIPTION_KEY;
export const HOME_ID = 0;
export const MONTHVIEW_ID = 1;
export const SHIFTPATTERN_ID = 2;
export const ROLECREATION_ID = 3;
export const USER_ADMINISTRATION_ID = 4;
export const ADMIN_LOCATIONS_ID = 5;
export const ADMIN_LEAVES_ID = 6;
export const CREW_CONFIGURATION_ID = 7;
export const isMobile = 576;
export const LOGIN = "/msLogin";
export const FORGOT_PASSWORD = "/forgot-password";
export const RECOVER_PASSWORD = "/recover-password";
export const HOME = "/home";
export const MONTH_VIEW = "/month-view";
export const SHIFT_PATTERN = "/shift-pattern";
export const USER_ADMINISTRATION = "/user-administration";
export const ROLE_CREATIOIN = "/role-creation";
export const ERROR_PAGE = "/error";
export const HAS_SHIFT_CODE = 410;
export const HAS_LEAVES_CODDE = 411;
export const HAS_TRANSFER_CODE = 412;
export const ADMIN_LOCATIONS = "/admin-locations";
export const ADMIN_LEAVES = "/admin-leaves";
export const CREW_CONFIGURATION = "/crew-configuration";
export const MS_LOGIN = "/msLogin";
export const SUPER_ADMIN_RESOURCES = [
  {
    id: 6,
    name: "Location Administration",
    urlPattern: "/admin-locations",
    key: "admin-locations"
  },
  {
    id: 7,
    name: "Crew Configuration",
    urlPattern: "/crew-configuration",
    key: "crew-configuration"
  },
  {
    id: 9,
    name: "/set-user-password",
    key: "set-user-password"
  }
]

export const PAGE_IDS = [
  HOME_ID, 
  MONTHVIEW_ID, 
  SHIFTPATTERN_ID, 
  ROLECREATION_ID, 
  USER_ADMINISTRATION_ID,
  ADMIN_LOCATIONS_ID,
  ADMIN_LEAVES_ID,
  CREW_CONFIGURATION_ID
]
