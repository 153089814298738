import Modal from "./Modal";
import classes from "./MultiOptionDialog.module.scss";

const MultiOptionDialog = ({ message, options = [
  {label: 'Confirm', callback: ()=>{}},
  {label: 'Cancel', callback: ()=>{}}
], className="", zIndex, onClose }) => {
  return (
    <Modal className={className} zIndex={zIndex} onClose={onClose}>
      <span className={classes.confirmationMessage}>{message}</span>
      <div className={classes.confirmationButtons}>
       {options.map((option, index) => 
        <button className={classes.confirmButton} onClick={option.callback} key={index}>
          {option.label}
        </button>)}
        {/* <button className={classes.cancelButton} onClick={onCancel}>
          {cancelLabel ?? 'Cancel'}
        </button> */}
      </div>
    </Modal>
  );
}

export default MultiOptionDialog;