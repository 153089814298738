import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./TransferUserModal.module.scss";
import Modal from "../UI/Modal";
import Dropdown from "../UI/Dropdown";
import UserInformation from "./UserInformation";
import useScheduleTimesAndSites from "../hooks/useScheduleTimesAndSites";
import UserRegisterContext from "../store/user-register-context";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InitialAndFinalDate from "../calendar/InitialAndFinalDate";
import { createEmployeeTransfer, createEmployeeTransferDeleteShift } from "../services/userRegisterService";
import {
  allLocations,
  selectedLocationState,
  selectedMonthState,
  selectedYearState,
} from "../../features/currentDataSlice";
import { HAS_LEAVES_CODDE, HAS_SHIFT_CODE, HAS_TRANSFER_CODE, HOME_ID } from "../services/global";
import InformationDialog from "../UI/InformationDialog";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";
import { showToast } from "../../features/toastNotificationSlice";
import useShiftForm, { emptyShift } from "../hooks/useShiftForm";
import ScheduleLabel from "./ScheduleLabel";
import { fetchCurrentMonthView } from "../../features/monthViewSlice";

const TransferUserModal = ({ employee, onClose }) => {
  const userRegisterCtx = useContext(UserRegisterContext);

  //GET DATA FROM SLICE
  const selectedLocation = useSelector(selectedLocationState);
  const loadedLocations = useSelector(allLocations);
  const selectedMonthSlice = useSelector(selectedMonthState);
  const selectedYearSlice = useSelector(selectedYearState);
  const { schedules, selectedSchedule, setSelectedSchedule } = useScheduleTimesAndSites(null, selectedLocation);

  const [radioValue, setRadioValue] = React.useState("0");
  const [startDate, setStartDate] = useState({ date: null, formattedDate: "" });
  const [endDate, setEndDate] = useState({ date: null, formattedDate: "" });
  const [location, setLocation] = useState({});
  const [infoMessage, setInfoMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [onConfirmData, setOnConfirmData] = useState({});

  const { shiftState, locationSites, locationSchedules, updateShift } = useShiftForm({ shift: null, currentLocation: location });
  const siteSchedules = locationSchedules?.[shiftState?.site?.id] ?? [];


  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sites && sites[0]) setSelectedSite(sites[0])
  }, [sites]);

  useEffect(() => {
    if (location?.sites) {
      setSites(location.sites.filter(site => site.enabled));
    }
  }, [location])

  const onConfirm = async () => {
    setShowDialog(false);
    if (onConfirmData) {
      try {
        const response = await createEmployeeTransferDeleteShift(onConfirmData);
        if (response.responseCode === 200) {
          userRegisterCtx.fetchWeekData(
            selectedLocation,
            userRegisterCtx.selectedWeek.startDate.toISOString().slice(0, 10),
            userRegisterCtx.selectedWeek.endDate.toISOString().slice(0, 10),
            selectedYearSlice,
            selectedMonthSlice,
            HOME_ID
          );
          let transferStartDate = new Date(`${startDate.date}T00:00`);
          dispatch(
            fetchCurrentMonthView({
              monthId: transferStartDate.getMonth() + 1,
              locationId: selectedLocation.id,
              year: transferStartDate.getFullYear(),
            })
          );
          onCancel();
          onClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const onCancel = () => {
    setShowDialog(false);
    setOnConfirmData({});
    setInfoMessage("");
  };

  const closeInfoDialog = () => {
    setShowInfoDialog(false);
  };

  const locationHandler = (location) => {
    setLocation(location);
  };

  const onSaveDateHandler = (day, type) => {
    if (type === 0) setStartDate(day);
    if (type === 1) setEndDate(day);
  };

  const handleSelectedSite = (site) => {
    updateShift('site', site);
    const selectedSiteSchedules = locationSchedules?.[site?.id] ?? [];
    const newSchedule = selectedSiteSchedules[0] ?? null
    updateShift('scheduleTime', newSchedule);
  };

  const handleSelectedSchedule = (time) => {
    updateShift('scheduleTime', time);
  };

  const radioHandleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const saveTransferHanlder = async () => {
    const data = {
      id: null,
      employee: {
        id: employee.id,
      },
      fromLocation: selectedLocation.id,
      toLocation: location.id,
      startDate: startDate.date,
      endDate: endDate.date,
      status: "ACTIVE",
      site: shiftState.site,
      scheduleTime: shiftState.scheduleTime
    };
    try {
      const responseData = await createEmployeeTransfer(data);
      if (responseData?.responseCode === 200) {
        userRegisterCtx.fetchWeekData(
          selectedLocation,
          userRegisterCtx.selectedWeek.startDate.toISOString().slice(0, 10),
          userRegisterCtx.selectedWeek.endDate.toISOString().slice(0, 10),
          selectedYearSlice,
          selectedMonthSlice,
          HOME_ID
        );
        let transferStartDate = new Date(`${startDate.date}T00:00`);
        dispatch(
          fetchCurrentMonthView({
            monthId: transferStartDate.getMonth() + 1,
            locationId: selectedLocation.id,
            year: transferStartDate.getFullYear(),
          })
        );
        onClose();
      } else {
        if (responseData.responseCode === HAS_SHIFT_CODE) {
          let message = "";
          if (responseData.message.includes(" - ")) {
            message = `Employee has assigned shifts on ${responseData.message}, Do you want to delete them?`;
          } else {
            message = `Employee has assigned shift on ${responseData.message}, Do you want to delete it?`;
          }
          setInfoMessage(message);
          setOnConfirmData(data);
          setShowDialog(true);
        } else if (responseData.responseCode === HAS_LEAVES_CODDE) {
          const message = `Employee has assigned leaves on: ${responseData.message}`;
          setInfoMessage(message);
          setShowInfoDialog(true);
        } else if (responseData.responseCode === HAS_TRANSFER_CODE) {
          const message = `Employe has transfers on: ${responseData.message}`
          setInfoMessage(message);
          setShowInfoDialog(true);
        } else {
          dispatch(showToast({ type: 'warning', message: 'Something went wrong while creating the transfer' }));
        }
      }
    } catch (error) {
      console.log(error.message);
      dispatch(showToast({ type: 'warning', message: 'Something went wrong while creating the transfer' }));
    }
  };

  const [locationOptions, setLocationOptions] = useState([]);
  useEffect(() => {
    setLocationOptions(loadedLocations.filter(location => location.id !== selectedLocation.id));
  }, [loadedLocations, selectedLocation])

  useEffect(() => {
    locationHandler(locationOptions[0]);
  }, [locationOptions])

  return (
    <>
      <Modal
        className={"employeeInfoContainer"}
        onClose={onClose}
        id={"transferUserDialog"}
      >
        <div className={classes.header}>
          <div className={classes.title}>
            <span
              className={`icon-transfer-user ${classes.transferIcon}`}
            ></span>
            <span className={classes.text}>Transfer User</span>
          </div>
          <div className={classes.buttonsContainer}>
            <button
              onClick={() => {
                onClose();
              }}
              className={classes.cancelBtn}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                onClose();
              }}
              className={classes.cancelBtnMobile}
            >
              <span className="icon-close"></span>
            </button>
            <button onClick={saveTransferHanlder} className={classes.doneBtn}>
              Save
            </button>
          </div>
        </div>
        <div className={classes.userContainer}>
          <UserInformation user={employee} showSkillsUnderShortName={true} />
          <div className={classes.shiftSummarey}>
            <span className={classes.title}>Current Shift Summary</span>
            {/* ! calculate date and shift indicators */}
            <span>Monday, Feb 14, 2023 - EH</span>
          </div>
        </div>
        <div className={classes.shiftInfo}>
          <div className={classes.siteContainer}>
            <span className={classes.dropdownTitle}>Location</span>
            <Dropdown
              className="sitesDropdown"
              dropdownContainer="sitesDropdownContainer"
              options={locationOptions}
              defaultValue={locationOptions[0]}
              icon={"icon-location"}
              iconClass={"location"}
              onClick={locationHandler}
              showUserInfo={false}
              showSearch={true}
              windowId={"transferUserDialog"}
              displayProperty={"label"}
            ></Dropdown>
          </div>
          <div className={classes.dropdownContainer}>
            <div className={classes.siteContainer}>
              <span className={classes.dropdownTitle}>Site</span>
              <Dropdown
                className="sitesDropdown"
                dropdownContainer="sitesDropdownContainer"
                options={locationSites}
                defaultValue={shiftState.site}
                onClick={handleSelectedSite}
                showUserInfo={false}
                showSearch={false}
                disabled={!(locationSites?.length > 0)}
                disabledPlaceholder={'No active sites in location'}
                windowId={"transferUserDialog"}
                displayProperty={"name"}
              ></Dropdown>
            </div>
            <div className={classes.siteContainer}>
              <span className={classes.dropdownTitle}>Time slot</span>
              <Dropdown
                className="sitesDropdown"
                dropdownContainer="sitesDropdownContainer"
                options={siteSchedules}
                defaultValue={shiftState.scheduleTime}
                icon={"icon-check"}
                iconClass={"checkIcon"}
                onClick={handleSelectedSchedule}
                showUserInfo={false}
                showSearch={false}
                getLabel={ScheduleLabel}
                disabled={!(siteSchedules?.length > 0)}
                disabledPlaceholder={'No active schedules in site'}
                windowId={"transferUserDialog"}
                displayProperty={"label"}
              ></Dropdown>
            </div>
          </div>
          <InitialAndFinalDate
            onSaveDateHandler={onSaveDateHandler}
          ></InitialAndFinalDate>
        </div>
        {/* <span className={classes.titleText} >Applicable Dates</span> */}
      </Modal>
      {showDialog && (
        <DeleteConfirmationDialog
          message={infoMessage}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
      {showInfoDialog && (
        <InformationDialog message={infoMessage} onCancel={closeInfoDialog} />
      )}
    </>
  );
};

export default TransferUserModal;