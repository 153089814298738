import { createSlice, current, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteFolder, deleteRole, getFunctionalities, getLocations, getRoleFolderTree, saveFolder, saveRole } from '../components/services/roleManagementService';
import { getResourceActions } from '../components/services/resourcePermissionsService';
import { showToast } from './toastNotificationSlice';
import { setShowErrorPage } from './currentDataSlice';
import { sortTree } from '../utils/utils';


const rolesSlice = createSlice({
  name: 'rolesFolder',
  initialState: {
    folderTree: [],
    selectedItem: { item: {}, idArray: [], isFolder: false },
    radioOption: 'regular_user',
    locations: [],
    functionalities: [],
    folderFilter: '',
    newItem: { id: null, isFolder: null }
  },
  reducers: {
    selectFolderItem(state, action) {
      let currentState = JSON.parse(JSON.stringify(current(state)));
      let idArray = action.payload.idArray;
      let containerItem = { folders: currentState.folderTree };
      for (let j = 0; j < idArray.length; j++) {
        containerItem = (j < idArray.length - 1) ?
          containerItem.folders.find(folder => folder.id === idArray[j]) :
          (action.payload.isFolder) ?
            containerItem.folders.find(folder => folder.id === idArray[j])
            : containerItem.roles.find(folder => folder.id === idArray[j]);
      }

      if (!action.payload.isFolder) {
        state.locations = currentState.locations.map(location => {
          location.enabled = containerItem.roleLocations?.map(item => item.location.id).includes(location.id);
          return location
        });
        state.functionalities = currentState.functionalities.map(resource => {
          for (let action of resource.actions) {
            action.enabled = containerItem.actions?.map(itemAction => itemAction.id).includes(action.id);
          }
          return resource;
        });
      }

      state.selectedItem = { item: containerItem, idArray: action.payload.idArray, isFolder: action.payload.isFolder };
      state.folderTree = currentState.folderTree;
    },
    selectRadioOption(state, action) {
      state.radioOption = action.payload.filter(option => option.selected)[0]?.value;
    },
    switchLocation(state, action) {
      state.locations = state.locations.map(location => {
        if (location.id === action.payload.locationId) location.enabled = action.payload.enabled;
        return location;
      })
    },
    switchAction(state, action) {
      state.functionalities = state.functionalities.map(functionality => {
        for (let resourceAction of functionality.actions) {
          if (action.payload.actionId === resourceAction.id) resourceAction.enabled = action.payload.enabled;
        }
        return functionality;
      }
      )
    },
    filterFolders(state, action) {
      state.folderFilter = action.payload;
    },
    clearSelectedItem(state) {
      state.selectedItem = { item: {}, idArray: [], isFolder: false }
    }
  }, extraReducers: (builder) => {
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.locations = action.payload.map(location => {
        location.label = `${location.value} ${location.label}`
        location.enabled = false;
        return location
      });
    });
    builder.addCase(fetchFunctionalities.fulfilled, (state, action) => {
      state.functionalities = action.payload;
    });
    builder.addCase(fetchFolders.fulfilled, (state, action) => {
      state.folderTree = action.payload;
    });
    builder.addCase(saveNewFolder.fulfilled, (state, action) => {
      if (action.payload) {
        let idArray = action.payload.at;
        let folder = action.payload.newFolder;
        let currentState = JSON.parse(JSON.stringify(current(state)));
        let containerItem = { folders: currentState.folderTree };
        for (let j = 0; j < idArray.length; j++) {
          containerItem = containerItem.folders.find(folder => folder.id === idArray[j]);
        }
        containerItem.folders.push(folder);
        containerItem.folders.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        state.selectedItem = { item: folder, idArray: [...idArray, folder.id], isFolder: true };
        state.folderTree = currentState.folderTree;
        state.newItem = { id: folder.id, isFolder: true }

      }
    });
    builder.addCase(saveNewRole.fulfilled, (state, action) => {
      if (action.payload) {
        let idArray = action.payload.at;
        let role = action.payload.newRole;
        let currentState = JSON.parse(JSON.stringify(current(state)));
        let containerItem = { folders: currentState.folderTree };
        for (let j = 0; j < idArray.length; j++) {
          containerItem = containerItem.folders.find(folder => folder.id === idArray[j]);
        }
        containerItem.roles.push(role);
        containerItem.roles.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        state.locations = currentState.locations.map(location => {
          location.enabled = role.roleLocations?.map(item => item.location.id).includes(location.id);
          return location
        });
        state.functionalities = currentState.functionalities.map(resource => {
          for (let action of resource.actions) {
            action.enabled = role.actions?.map(itemAction => itemAction.id).includes(action.id);
          }
          return resource;
        });
        state.selectedItem = { item: role, idArray: [...idArray, role.id], isFolder: false };
        state.folderTree = currentState.folderTree;
        state.newItem = { id: role.id, isFolder: false }
      }
    });
    builder.addCase(editSelectedItem.fulfilled, (state, action) => {
      if (action.payload) {
        let idArray = action.payload.at;
        let editedItem = action.payload.editedItem;
        let isFolder = action.payload.isFolder;
        let currentState = JSON.parse(JSON.stringify(current(state)));
        let containerItem = { folders: currentState.folderTree };
        for (let j = 0; j < idArray.length - 1; j++) {
          containerItem = containerItem.folders.find(folder => folder.id === idArray[j]);
        }
        let contentArray = containerItem[isFolder ? 'folders' : 'roles'];
        let previousItem = contentArray.find(item => item.id === editedItem.id);
        contentArray.splice(contentArray.indexOf(previousItem), 1, editedItem);
        state.selectedItem = { item: editedItem, idArray: idArray, isFolder: isFolder };

        state.folderTree = currentState.folderTree;
      }
    });
    builder.addCase(deleteItem.fulfilled, (state, action) => {
      if (action.payload) {
        let idArray = action.payload.idArray;
        let isFolder = action.payload.isFolder;
        let currentState = JSON.parse(JSON.stringify(current(state)));
        let containerItem = { folders: currentState.folderTree };
        for (let j = 0; j < idArray.length - 1; j++) {
          containerItem = containerItem.folders.find(folder => folder.id === idArray[j]);
        }
        let contentArray = containerItem[isFolder ? 'folders' : 'roles'];
        let itemToDelete = contentArray.find(item => item.id === idArray[idArray.length - 1]);
        contentArray.splice(contentArray.indexOf(itemToDelete), 1);
        if ((JSON.stringify(currentState.selectedItem.idArray) === JSON.stringify(idArray))
          && (currentState.selectedItem.isFolder === isFolder)) {
          state.selectedItem = { item: {}, idArray: [], isFolder: false };
        }
        state.folderTree = currentState.folderTree;
        state.selectedItem = { item: {}, idArray: [], isFolder: false };
      }
    })

  }
});

export const fetchLocations = createAsyncThunk('roles/fetchLocations',
  async () => {
    const locations = await getLocations();
    return locations;
  }
)

export const fetchFolders = createAsyncThunk('roles/fetchFolders',
  async () => {
    let folders = await getRoleFolderTree();
    let rootFolder = sortTree({
      baseElement:{folders: folders.filter(folder => folder.parentId === null), roles:[]}, 
      nestingProperty: "folders",
      elementsProperty: "roles",
      sortBy: ["name"]
    });
    return rootFolder.folders;
  }
)

export const fetchFunctionalities = createAsyncThunk('roles/fetchFunctionalities',
  async () => {
    const functionalities = await getFunctionalities();
    for (let functionality of functionalities) {
      let resourceActions = await getResourceActions(functionality.key);
      functionality.actions = resourceActions.permissions.map(action => {
        action.label = action.name;
        action.enabled = false;
        return action;
      });
      functionality.label = functionality.name;

    }
    return functionalities;
  }
)

export const saveNewFolder = createAsyncThunk('roles/saveNewFolder',
  async (newFolderInfo, { dispatch }) => {
    let idArray = newFolderInfo.isSelectedItemFolder ? newFolderInfo.at : newFolderInfo.at.slice(0, -1);
    let parentId = idArray[idArray.length - 1] ?? null;
    let folderToSave = {
      name: newFolderInfo.name,
      parentId: parentId,
      folders: [],
      roles: []
    }
    let response = await saveFolder(folderToSave);
    if (response.success) {
      dispatch(showToast({ type: 'success', message: 'Folder created successfully' }));
      return { newFolder: response.folder, at: idArray };
    } else {
      if (response.message === 'request failed') {
        dispatch(setShowErrorPage(true));
      } else {
        dispatch(showToast({ type: 'warning', message: response.message }));
      }
      return null;
    }
  }
)

export const saveNewRole = createAsyncThunk('roles/saveNewRole',
  async (newRoleInfo, { dispatch }) => {
    let idArray = newRoleInfo.isSelectedItemFolder ? newRoleInfo.at : newRoleInfo.at.slice(0, -1);
    let parentId = idArray[idArray.length - 1] ?? null;
    let roleToSave = {
      name: newRoleInfo.name,
      folderId: parentId,
      actions: [],
      roleLocations: [],
      type: 'regular_user'
    }
    let response = await saveRole(roleToSave);
    if (response.success) {
      dispatch(showToast({ type: 'success', message: 'Role created successfully' }));
      return { newRole: response.role, at: idArray };
    } else {
      if (response.message === 'request failed') {
        dispatch(setShowErrorPage(true));
      } else {
        dispatch(showToast({ type: 'warning', message: response.message }));
      }
      return null;
    }
  }
)

export const editSelectedItem = createAsyncThunk('roles/editItem',
  async (editInfo, { dispatch }) => {
    if (editInfo.isFolder) {
      let response = await saveFolder(editInfo.editedItem);
      if (response.success) {
        dispatch(showToast({ type: 'success', message: response.message }));
        return { editedItem: response.folder, at: editInfo.idArray, isFolder: editInfo.isFolder };
      } else {
        if (response.message === 'request failed') {
          dispatch(setShowErrorPage(true));
        } else {
          dispatch(showToast({ type: 'warning', message: response.message }));
        }
        return null;
      }
    } else {
      let response = await saveRole({
        ...editInfo.editedItem, 
        resources: editInfo.editedItem.resources.map(resource => ({id: resource.id})),
        actions: editInfo.editedItem.actions.map(action => ({id: action.id})),
        roleLocations: editInfo.editedItem.roleLocations.map(location => ({location:{id: location.location.id}}))
      });
      if (response.success) {
        dispatch(showToast({ type: 'success', message: response.message }));
        return { editedItem: response.role, at: editInfo.idArray, isFolder: editInfo.isFolder };
      } else {
        if (response.message === 'request failed') {
          dispatch(setShowErrorPage(true));
        } else {
          dispatch(showToast({ type: 'warning', message: response.message }));
        }
        return null;
      }
    }
  }
);

export const deleteItem = createAsyncThunk('roles/deleteItem',
  async (deleteInfo, { dispatch }) => {
    let response = (deleteInfo.isFolder) ?
      await deleteFolder(deleteInfo.idArray[deleteInfo.idArray.length - 1]) :
      await deleteRole(deleteInfo.idArray[deleteInfo.idArray.length - 1]);
    if (response.success) {
      dispatch(showToast({ type: 'success', message: response.message }));
      return deleteInfo;
    } else {
      if (response.message === 'request failed') {
        dispatch(setShowErrorPage(true));
      } else {
        dispatch(showToast({ type: 'warning', message: response.message }));
      }
      return null;
    };
  }
)

export const sortItems = (a, b) => {
  if (a.type === 'folder' && b.type !== 'folder') {
    return -1;
  } else if (a.type !== 'folder' && b.type === 'folder') {
    return 1
  } else {
    return a.name.localeCompare(b.name);
  }
}

export const {
  selectFolderItem,
  selectFolder,
  selectRadioOption,
  switchLocation,
  switchAction,
  filterFolders,
  clearSelectedItem,
} = rolesSlice.actions;
export const roleManagementFolders = (state) => state.rolesFolder.folderTree;
export const locations = (state) => state.rolesFolder.locations;
export const functionalities = (state) => state.rolesFolder.functionalities;
export const actions = (state) => state.rolesFolder.functionalities.map(functionality => functionality.actions).flat()
export const selectedRoleItem = (state) => state.rolesFolder.selectedItem;
export const newItemState = (state) => state.rolesFolder.newItem;
export const idArray = (state) => state.rolesFolder.selectedItem.idArray;
export const globalRadioOption = (state) => state.rolesFolder.radioOption;
export const folderFilter = (state) => state.rolesFolder.folderFilter;
export default rolesSlice.reducer;