import React, { useState } from "react";
import classes from './Checkbox.module.scss';

const Checkbox = ({ checked, onChange, children, whiteBorder = false, justify="left" }) => {
  return (
    <div className={`${classes.wrapper} ${classes[justify]}`}>
      <label className={classes.checkboxContainer}>
        <input className={classes.customCheckbox} checked={checked} onChange={(e) => onChange(e.target.checked)} type="checkbox" />
        <span className={`${classes.checkmark} ${whiteBorder ? classes.whiteBorder : ''}`}></span>
      </label>
      {children}
    </div>
  );
}

export default Checkbox;