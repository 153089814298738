import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { HOME_ID, SUPER_ADMIN_RESOURCES } from "../components/services/global";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    admin: null,
    name: null,
    token: null,
    userId: null,
    username: null,
    active: null,
    resources: [],
    viewId: HOME_ID,
    role: null
  },
  reducers: {
    login: (state, action) => {
      const { admin, name, token, userId, username, active, resources, role } = action.payload;
      state.admin = admin;
      state.name = name;
      state.token = token;
      state.userId = userId;
      state.username = username;
      state.active = active;
      state.resources = role.type === "admin" ? [...resources, ...SUPER_ADMIN_RESOURCES]: resources;
      state.viewId = HOME_ID;
      state.role = role;
      // Save JWT token as a cookie
    },
    logout: (state) => {
      state.admin = null;
      state.name = null;
      state.token = null;
      state.userId = null;
      state.username = null;
      state.active = false;
      state.resources = [];
      state.viewId = HOME_ID;
      state.role = null;

      // Remove secure cookie
      Cookies.remove("token");
    },
    handlerViewPageChange: (state, action) => {
      state.viewId = action.payload;
    },
    showLoginHandler: (state, action) => {
      state.active = action.payload;
    }
  },
});

export const logOut = createAsyncThunk(
  "user/logOut",
  async (_, { getState, dispatch }) => {
    dispatch(logout());
  }
);


export const { login, logout, handlerViewPageChange, showLoginHandler } = userSlice.actions;

export const isAdminActive = (state) => state.user.active;
export const activeUserState = (state) => state.user;

export const resources = (state) => state.user.resources;
export const role = (state) => state.user.role;
export default userSlice.reducer;