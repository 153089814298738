import React, { useState, useEffect } from "react";
import classes from "./LeaveManagement.module.scss";
import InformationDialog from "../UI/InformationDialog";

const LeaveManagement = ({ data, leaveType, onDeleteLeave, deleteActionPermission }) => {
  const itemsPerPage = 3; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsLenght, setItemsLenght] = useState(0);
  const totalItems = itemsLenght;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [filterData, setFilterData] = useState([]);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array based on the current page
  const displayedData = filterData.slice(0, endIndex);

  const handleViewMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const showInfoDialogToggle = () => {
    setShowInfoDialog(!showInfoDialog);
  };

  const onDelete = (leave) => {
    onDeleteLeave(leave.id)
  }

  // Filter the data array based on conditions
  useEffect(() => {
    const filter = data.filter(
      (date) =>
        !date?.vacation && date?.leaveType?.type?.toUpperCase() === leaveType
    );
    setFilterData(filter);
    setItemsLenght(filter.length);
  }, [data, leaveType]);

  return (
    <div className={classes.permissionContent}>
      <div className={classes.dateListContainer}>
        {displayedData &&
          displayedData.map((date) => {
            if (
              !date?.vacation &&
              date?.leaveType?.type?.toUpperCase() === leaveType
            ) {
              return (
                <div key={date.id} className={classes.container}>
                  <div className={classes.dateContainer}>
                    <span className={classes.date}>
                      {date?.startDate} - {date?.endDate}
                    </span>
                    <span className={classes.dateSubtitle}>Date Range</span>
                  </div>
                  <div className={classes.commentsContainer}>
                    <div className={classes.leavesContainer}>
                      <span className={classes.leave}>
                        {date.leaveType?.name}
                      </span>
                      <span className={classes.businessDays}>
                        {date?.businessDays} Business Days
                      </span>
                    </div>
                    <div
                      onClick={showInfoDialogToggle}
                      className={classes.commentIconContainer}
                    >
                      <span className="icon-comment"></span>
                    </div>
                    {deleteActionPermission && <span onClick={() => onDelete(date)} className={`icon-delete ${classes.iconDelete}`}></span>}
                  </div>
                  {showInfoDialog && (
                    <InformationDialog
                      message={date.description}
                      onCancel={showInfoDialogToggle}
                    />
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
      {displayedData.length > 0 && currentPage < totalPages && (
        <button className={classes.viewMore} onClick={handleViewMore}>
          + View more
        </button>
      )}
    </div>
  );
};

export { LeaveManagement };