
import { useEffect } from 'react';
import TimeField from 'react-simple-timefield';


const HourInput2 = ({name, value, onChange, disabled, onFocus, onBlur, className}) => {

  const handleChange = (e, newValue) => {
    onChange({target:{name, value: newValue}})
  }

  return (
    <>
      {/* <input name={"name"} onFocus={autoCursorPositon} onChange={inputChange} value={value.padEnd(5,'--:--')} id={`${name}time`}/> */}
      <TimeField
        value={value}
        name={name}
        onChange={handleChange}
        input={<input className={className} id={`${name}timeInput`} disabled={disabled} onFocus={onFocus} onBlur={onBlur}/>}
        colon=":"
      />
    </>
  );
}

export default HourInput2;