import React, { cloneElement, useEffect } from "react";
import classes from './Input.module.scss';
import HourInput2 from "./HourInput2";
import TextArea from "./TextArea";
import DateInput from "./DateInput";
import { useState } from "react";

const openColorPicker = (id) => {
  const element = document.getElementById(id);
  element.click();
}

const inputElements = {
  text: (props) => cloneElement(<input />, props),
  time: HourInput2,
  color: (props) => cloneElement(<input />, props),
  area: TextArea,
  date: DateInput
}

const Input = ({
	id,
  name,
  value,
  onChange,
  label,
  placeholder,
  icon,
  iconPosition = 'left',
  iconClassName,
  align = 'left',
  type = "text",
  theme = "dark",
  disabled,
  maxLength,
  showCount,
  maxLengthMessage }) => {

  const Component = inputElements[type];
  const [active, setActive] = useState(false);

  return (
    <div className={`${classes.field} ${classes[theme]} ${classes[type]} ${disabled ? classes.disabled : ''} ${classes[align]} ${active ? classes.focused : ''}`}>
      <div className={`${classes.inputWrapper} ${icon ? classes[iconPosition] : ''}`}>
        {icon && <i className={`${icon} ${iconClassName}`} />}
        {type === 'color' && <span onClick={() => openColorPicker(id ?? name)} style={{ backgroundColor: value }}></span>}
        <Component
          id={id ?? name}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          showCount={showCount}
          maxLengthMessage={maxLengthMessage}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        />
      </div>
      {label && <label htmlFor="scheduleLabel">{label}</label>}
    </div>);
};

export default Input;