import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";

const useWeekDaysState = (shifts) => {
  const [isWorkShiftShown, setIsWorkShiftShown] = useState(false);
  const [userShift, setUserShift] = useState({});
  const [user, setUser] = useState(shifts);
  const [shiftIndex, setShiftIndex] = useState(null);
  const dispatch = useDispatch();

  const showWorkShiftHandler = (shift, index) => {
    if (shift.transmittedStatus === 'publish') {
      dispatch(showToast({ type: 'info', message: "Shift is published. Please unpublish to edit.", duration: 7000 }));
    } else { 
      setUserShift(shift);
      setShiftIndex(index);
      setUser(shifts);
      setIsWorkShiftShown(true);
    }
  };

  const hideShowWorkShiftHandler = () => {
    setIsWorkShiftShown(false);
  };

  return {
    isWorkShiftShown,
    userShift,
    user,
    shiftIndex,
    showWorkShiftHandler,
    hideShowWorkShiftHandler,
  };
};

export default useWeekDaysState;
