import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";
import { alphabetSort, numberSort, replaceFalsyInArray, testModify } from "../../utils/utils";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function getMonthViewData(monthId, locationId, year) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(
    `${API_BASE_URL}/get-month-schedule?locationId=${locationId}&year=${year}&month=${monthId}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  
  if (!response.ok){
    return response;
  }

  const data = await response.json();
  return {
    ...data,
    load: {
      ...data.load,
      groups: numberSort({
        array: data.load.groups.map(group => ({
          ...group,
          employees: alphabetSort({
            array: replaceFalsyInArray(group.employees, 'employee.userName', 'employee.employeeName'),
            properties: ['employee.userName']
          })
        })),
        properties: ['groupOrder'],
        keepTop: (crew => crew.id === 'transfer'),
        keepLast: (crew => crew.id === null)
      })
    }
  };
}