import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAirCraftType } from "../components/services/filterService";
import { alphabetSort } from "../utils/utils";

const aircraftsSlice = createSlice({
  name: 'aircrafts',
  initialState: {
    aircrafts: {}
  },
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(fetchAircrafts.fulfilled, (state, action) => {
      state.aircrafts = action.payload.reduce((acc, aircraft) => ({
        ...acc,
        [aircraft.id]: aircraft,
      }), {})
    });
  }
})

export const fetchAircrafts = createAsyncThunk("aircrafts/fetchAircrafts",
  async () => {
    const response = await getAirCraftType();
    return response.load.map(aircraft => ({
        ...aircraft, endorsements: alphabetSort({
          array: aircraft.endorsements,
          properties: ['name']
        })
      }));
  }
);

export default aircraftsSlice.reducer;

export const selectAircrafts = (state) => state.aircrafts.aircrafts; 