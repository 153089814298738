import { createSlice } from '@reduxjs/toolkit';

const toastNotificationSlice = createSlice({
  name: 'toastNotification',
  initialState: {
    open: false,
    type: 'info',
    message: '',
    duration: 2000
  },
  reducers: {
    showToast(state, action) {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.open = true;
      state.duration = action.payload.duration === 'keep-open' ? null : (action.payload.duration ?? 2000);
    },
    closeToast(state) {
      state.open = false;
    }
  },
});

export const { showToast, closeToast } = toastNotificationSlice.actions;
export const toastOpen = (state) => state.toastNotification.open;
export const toastMessage = (state) => state.toastNotification.message;
export const toastType = (state) => state.toastNotification.type;
export const toastDuration = (state) => state.toastNotification.duration;

export default toastNotificationSlice.reducer;