import React, { useContext, useEffect } from "react";
import classes from "./MicrosoftLoginButton.module.scss"
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../config/MsAuthConfig";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";
import { validateMicrosoftToken } from "../services/microsoftLoginService";
import { months } from "moment";
import { getCurrentMonth, getYears, loadYearMonth } from "../services/dateTimeService";
import { getAllLocations } from "../services/dropdownService";
import { setSelectedLocationState, setSelectedMonthState, setSelectedYearState, setloadedLocations, updateCurrentLocationId, updatecurrentMonthId, updatecurrentYearId } from "../../features/currentDataSlice";
import UserRegisterContext from "../store/user-register-context";
import { HOME_ID } from "../services/global";
import { setLoading } from "../../features/loadingSlice";
import { login } from "../../features/userSlice";
import { fetchResourcePermissions } from "../../features/resourcePepermissionsSlice";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

const MicrosoftLoginButton = () => {

  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const userRegisterCtx = useContext(UserRegisterContext);

  const updateCurrentDataSlice = (locations, month, year, locationId) => {
    dispatch(setloadedLocations(locations));
    dispatch(setSelectedLocationState(locations.find(l=>l.id===locationId) ?? locations[0]));
    dispatch(setSelectedMonthState(month));
    dispatch(setSelectedYearState(year));
    dispatch(updateCurrentLocationId(locations.find(l=>l.id===locationId)?.id ?? locations[0].id));
    dispatch(updatecurrentMonthId(month.id));
    dispatch(updatecurrentYearId(year.id));
  }

  const loadResourcer = async () => {
    //first load locations
    let locations = await getAllLocations();
    const [year, month, startDate, endDate, locationId] = loadYearMonth();
    if (locations.length >= 0 && month && year) {
      updateCurrentDataSlice(locations, month, year, locationId);
      userRegisterCtx.fetchWeekData(locations.find(loc => loc.id === locationId) ?? locations[0], startDate, endDate, year, month, HOME_ID);
      userRegisterCtx.fetchScheduleTimes();
    }
  };


  const microsoftLogin = () => {
    instance.loginPopup(loginRequest).then(
      data => {
        dispatch(setLoading(true));
        validateMicrosoftToken(data.accessToken).then(
          data => {
            if (data.success) {
              if (data.userData !== null) {
                dispatch(
                  login({
                    admin: data.userData.admin,
                    name: data.userData.name,
                    token: data.userData.token,
                    userId: data.userData.userId,
                    username: data.userData.username,
                    active: true,
                    resources: data.userData.resources,
                    role: data.userData.role
                  })
                );
                localStorage.setItem("msLogin", "1");
                dispatch(fetchResourcePermissions("week-view"));
                dispatch(setLoading(false));
                Cookies.set("token", data.userData.token, {
                  secure: true,
                  expires: 1,
                });
                loadResourcer();
                window.location.href = "/home"
              } else {
                dispatch(setLoading(false));
                dispatch(showToast({ type: 'warning', message: "Couldn't log you in with your microsoft account", duration: 4500 }))
              }
            } else {
              dispatch(setLoading(false));
              dispatch(showToast({ type: 'warning', message: "Couldn't log you in with your microsoft account", duration: 4500 }))
            }
          }
        )
      })
      .catch(error => {
        console.log(error);
        dispatch(setLoading(false));
        dispatch(showToast({ type: 'warning', message: "Couldn't log you in with your microsoft account", duration: 4500 }))
      });
  }



  return (
    <button type="button" onClick={microsoftLogin} className={classes.microsoftButton}>
      <span className="icon-microsoft-logo">
        <span className="path1"></span><span className="path2"></span>
        <span className="path3"></span><span className="path4"></span>
      </span>
      <span className={classes.buttonLabel}>Sign in with Microsoft</span>
    </button>
  );
}

export default MicrosoftLoginButton;