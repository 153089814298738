import { API_BASE_URL, API_BASE_URL_LOGIN, HEADER_SUBSCRIPTION_KEY } from "./global";

export async function validateMicrosoftToken (token) {
  try {
    const response = await fetch(`${API_BASE_URL_LOGIN}/validate-token-graph`, {
      headers:{
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      }
    });
     const data = await response.json();
    if (data.userId !== null) {
      return { userData:data, success: true };
    } else {
      console.log("error", data);
      return { useData: data, success: false, message: data.errorMessage };
    }
  } catch(error) {
    console.log(error);
    return {
      userData: null,
      success: false,
      message: "Couldn't log you in with yout Microsoft account",
    };
  }
}