import { useContext, useState, useEffect } from "react";
import classes from "./TransferUser.module.scss";
import Dropdown from "../UI/Dropdown";
import userRegisterContext from "../store/user-register-context";
import moment from "moment";

const getAllUsers = (currentWeekData) => {
  if (currentWeekData) {
    const users = currentWeekData.flatMap((group) =>
      group.employees.map((employee) => employee.employee)
    );
    return users;
  }
};

const TransferUser = ({
  shift,
  user,
  handleUserChange,
  handleSelectedShiftDate,
  disabled
}) => {
  const userRegisterCtx = useContext(userRegisterContext);
  const userList = getAllUsers(userRegisterCtx.currentWeekData);
  const [selectedShiftDate, setSelectedShiftDate] = useState({});
  const [transferEmployeeShifts, setTransferEmployeeShifts] = useState(
    Array.from({ length: 7 }, () => ({ id: null }))
  );

  const handleNewUserChange = (user) => {
    setTransferEmployeeShifts(user.shifts);
    handleUserChange(user);
  };

  const handleSelectedDate = (date, index) => {
    if (!disabled) {if (!transferEmployeeShifts[index].id && 
      !transferEmployeeShifts[index].leave) {
      setSelectedShiftDate(date);
      handleSelectedShiftDate(date);
    }}
  };

  useEffect(() => {
    const week = userRegisterCtx.selectedWeek.week;
    const shiftDate = shift.shiftDate;
    const date = week.filter(
      (day) =>
        moment.utc(day.date).format("MMMM Do YYYY") ===
        moment.utc(shiftDate).format("MMMM Do YYYY")
    );
    setSelectedShiftDate(date[0]);
  }, [userRegisterCtx.selectedWeek.week, shift.shiftDate]);

  return (
    <div className={classes.dateContainer}>
      <span className={classes.dateContainerTitle}>Change Date</span>
      <div className={classes.dateContainerInfo}>
        {userRegisterCtx.selectedWeek.week.map((date, index) => {
          return (
            <div key={date.id} onClick={() => handleSelectedDate(date, index)}>
              <span className={classes.dayName} >{date.name}</span>
              <div
                className={`${classes.dayContainer} 
                ${
                  transferEmployeeShifts[index].id === null &&
                  date.id === selectedShiftDate?.id
                    ? classes.selectedDay
                    : ""
                } 
                ${
                  (transferEmployeeShifts[index].id !== null ||
                  transferEmployeeShifts[index].leave) &&
                  classes.dayContainerDisabled
                }`}
              >
                {date.day}
              </div>
            </div>
          );
        })}
      </div>
      <span className={classes.dateContainerTitle}>Change Employee</span>
      <div className={classes.dropdownContainer}>
        <Dropdown
          className="userListDropdown"
          dropdownContainer="userDropdownContainer"
          userList="userList"
          userInfoClass="userInfoClass"
          users={userList}
          defaultValue={user}
          onClick={handleNewUserChange}
          showUserInfo={true}
          showSearch={true}
          disabled={disabled}
          windowId={"workShiftConfig"}
        ></Dropdown>
      </div>
    </div>
  );
};

export default TransferUser;
