import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import filterReducer from "../features/filterSlice";
import shiftPatternReducer from "../features/shiftPatternSlice";
import loadingReducer from "../features/loadingSlice";
import monthViewReducer from "../features/monthViewSlice";
import resourcePepermissionsReducer from "../features/resourcePepermissionsSlice";
import rolesReducer from "../features/rolesSlice";
import toastNotificationReducer from "../features/toastNotificationSlice";
import shiftCommentsReducer from "../features/shiftCommentsSlice";
import cosmosdbReducer from "../features/cosmosdbSlice";
import currentDataReducer from "../features/currentDataSlice";
import aircraftsReducer from "../features/aircraftsSlice";
import requiredShiftsReducer from "../features/requiredShiftsSlice"
import publishReducer from "../features/publishSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    filter: filterReducer,
    shiftPattern: shiftPatternReducer,
    loading: loadingReducer,
    monthView: monthViewReducer,
    resourcePermissions: resourcePepermissionsReducer,
    rolesFolder: rolesReducer,
    toastNotification: toastNotificationReducer,
    shiftComments: shiftCommentsReducer,
    cosmosdb: cosmosdbReducer,
    currentData: currentDataReducer,
    aircrafts: aircraftsReducer,
    requiredShifts: requiredShiftsReducer,
    publish: publishReducer
  }
})