import { Popover, ThemeProvider, createTheme } from '@mui/material';
import { DatePicker } from '../datepicker/DatePicker';
import classes from './DateInput.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useRef } from 'react';

const newTheme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          color: 'black',
          borderRadius: 4,
          borderWidth: 0,
          borderColor: '#00ca9a',
          border: '0px solid',
          backgroundColor: '#eFeFeF',
          fontSize: '0.85em',
          fontWeight: 'bold'
        },
        today: {
          borderRadius: 4,
          borderWidth: 0,
        },
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontFamily: "Poppins",
          fontWeight: 'bold',
          fontSize: '0.85em'
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          margin: '0.5em 0'
        },
        label: {
          fontFamily: "Poppins",
          fontSize: '0.9em'
        }
      }
    }
  }
});

const DateInput = ({ name, value, onChange, onFocus, onBlur, label }) => {
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const selectedDayReference = useRef();

  useEffect(() => {
    if (selectedDay?.isSame(selectedDayReference.current)) {
      onChange({ target: { name, value: selectedDay } });
      setOpen(false);

    }
    selectedDayReference.current = selectedDay;
  }, [selectedDay])

  const openPicker = (e) => {
    setAnchorElement(e.currentTarget);
    setOpen(true);
  }

  useEffect(() => {
    open ? onFocus() : onBlur()
  }, [open]);

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const saveDateHandler = () => {
    if (selectedDay) {
      onChange({ target: { name, value: selectedDay } })
    }
    setOpen(false);
  };

  const selectedYearMonth = () => {
    selectedDayReference.current = null;
  }

  return (
    <div className={classes.wrapper}>
      <input
        value={value}
        placeholder="MM/DD/YYYY"
        readOnly
        onClick={openPicker}
      />
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={newTheme}>
            <div className={classes.headerContainer}>
              <span>{label}</span>
              <button onClick={saveDateHandler}>OK</button>
            </div>
            <DateCalendar value={selectedDay} onChange={handleDayClick} views={['year', 'month', 'day']} onYearChange={selectedYearMonth} onMonthChange={selectedYearMonth} />
          </ThemeProvider>
        </LocalizationProvider>

      </Popover>
    </div>
  );

}

export default DateInput;