import { useEffect } from "react";
import { useState } from "react"
import { getSites } from "../services/userRegisterService";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";

const useSites = (indexed = false) => {
  const [sites, setSites] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      try {
        let response = await getSites();
        if (response.responseCode === 200) {
          setSites(response.load);
        } else {
          throw Error('An error occured while retrieving the sites.')
        }
      } catch (error) {
        console.log(error);
        dispatch(showToast({ type: 'warning', message: 'An error occurred while retrieving the sites.' }))
      }
    })();
  }, [dispatch, setSites])

  return indexed ? sites.reduce((sitesObject, site) => ({ ...sitesObject, [site.id]: site }), {}) : sites;

}

export default useSites;