import React, { useRef, useState } from "react";
import { format } from "date-fns";
import classes from "./AddNewLeave.module.scss";
import Dropdown from "../UI/Dropdown";
import { DatePicker } from "../datepicker/DatePicker";
import InitialAndFinalDate from "../calendar/InitialAndFinalDate";

const AddNewLeave = ({ closeAddNewLeave, employeeId, leaves, type }) => {
  const [startDate, setStartDate] = useState({date: null, formattedDate: ""});
  const [endDate, setEndDate] = useState({date: null, formattedDate: ""});
  const descriptionRef = useRef(null);
  const [leave, setLeave] = useState(leaves[0]);
  const [activeStartDate, setActiveStartDate] = useState(false);
  const [activeEndDate, setActiveEndDate] = useState(false);

  const onLeaveChange = (leave) => {
    setLeave(leave);
  };

  const toggleActiveStartDate = () => {
    if (activeEndDate === true) {
      setActiveEndDate(false);
    }
    setActiveStartDate(!activeStartDate);
  };

  const toggleActiveEndDate = () => {
    if (activeStartDate === true) {
      setActiveStartDate(false);
    }
    setActiveEndDate(!activeEndDate);
  };

  const saveNewLeaveHandler = () => {
    const newLeave = {
      employee: { id: employeeId },
      startDate: startDate.date,
      endDate: endDate.date,
      leaveType: leave,
      description: descriptionRef.current.value,
      vacation: false,
    };
    if (newLeave.startDate && newLeave.endDate && newLeave.leaveType) {
      closeAddNewLeave(newLeave);
    } else {
      closeAddNewLeave();
    }
  };

  const onSaveDate = (day) => {
    if (day) {
      const dateTotransform = new Date(day);
      const date = format(dateTotransform, "yyyy-MM-dd");
      const formattedDate = format(dateTotransform, "MM/dd/yyyy");
      const dateObj = {
        date,
        formattedDate
      }
      if (activeStartDate === true) {
        setStartDate(dateObj);
      }
      if (activeEndDate === true) {
        setEndDate(dateObj);
      }
    }
    if (activeStartDate === true) {
      setActiveStartDate(false);
    }
    if (activeEndDate === true) {
      setActiveEndDate(false);
    }
  };

  const onSaveDateHandler = (day, type) => {
    if (type === 0) setStartDate(day);
    if (type === 1) setEndDate(day);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>+ {type === 'T' ? 'New Training' : 'New Leave'}</span>
        <button onClick={saveNewLeaveHandler} className={classes.saveBtn}>
          Save
        </button>
        <button onClick={closeAddNewLeave} className={classes.closeBtn}>
          <span className={`icon-close ${classes.iconClose}`}></span>
        </button>
      </div>
      <div className={classes.inputContainer}>
        <div className={classes.containers}>
          <span className={classes.dropdownTitle}>Reason {type === 'T' ? '' : 'For Leave'}</span>
          <Dropdown
            onClick={onLeaveChange}
            className="leavesDropdown"
            dropdownContainer="sitesDropdownContainer"
            options={leaves}
            defaultValue={leaves[0]}
            showUserInfo={false}
            showSearch={false}
            windowId = {"employeeInfoDialog"}
          ></Dropdown>
        </div>
        <InitialAndFinalDate onSaveDateHandler={onSaveDateHandler}/>
      </div>
      <div className={classes.explanationContainer}>
        <span className={classes.dropdownTitle}>Explanation</span>
        <input className={classes.input} ref={descriptionRef}></input>
      </div>
      
    </div>
  );
};

export { AddNewLeave };
