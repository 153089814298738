import React, { useRef, useEffect } from 'react';

const ClickOutsideComponent = ({ children, handleClickOutside }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutsideEvent(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutsideEvent);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutsideEvent);
    };
  }, [handleClickOutside]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutsideComponent;
