import React, { useEffect, useRef, useState } from "react";
import classes from './EmployeeSkills.module.scss';
import { alphabetSort, defaultRoleColor, filterMap } from "../../utils/utils";
import { Popover } from "@mui/material";
import { selectAircrafts } from "../../features/aircraftsSlice";
import { useSelector } from "react-redux";

const EmployeeSkills = ({ skillList, showLabel = true, badgeShape = 'square', threshold = 4 }) => {
  const aircrafts = useSelector(selectAircrafts);
  const [employeeSkills, setEmployeeSkills] = useState([]);
  const [anchorElement, setAnchorElement] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let skillSet = skillList;
    let skillIdSet = skillSet.reduce((set, obj)=>({...set, [obj.aircraftTypeId]:{endorsements:new Set([...(set[obj.aircraftTypeId]?.endorsements ?? []), obj.endorsementId])}}),{})
    let skillArray = Object.entries(skillIdSet).map(([aircraftId, endorsementSet]) => ({...aircrafts[aircraftId] ?? {}, endorsements: aircrafts[aircraftId]?.endorsements.filter(e => endorsementSet.endorsements.has(e.id)) ?? [] }))
    
    if (Array.isArray(skillArray) && skillArray.length > 0) {
      setEmployeeSkills(
        alphabetSort({
          array: skillArray,
          properties: ['name']
        })
      );
    }
  }, [aircrafts, skillList]);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorElement(e.currentTarget)
    setOpen(true);
  }

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  }

  const badgeOverflow = employeeSkills?.length > threshold;

  return (employeeSkills?.length > 0 &&
    <>
      <div className={classes.skillsContainer} onClick={handleClick}>
        <div className={`${classes.badgeList} ${badgeShape === 'circle' ? classes.circlesList : classes.squareList}`}>
          {employeeSkills?.map((skill, index) =>
            skill?.name &&
            (badgeOverflow ? (index < threshold - 1) : (index < threshold)) && (
              <span
                key={index}
                className={`${badgeShape === 'square' ?
                  `icon-squarebadge ${classes.squareBadge}` :
                  `icon-circle ${classes.circleBadge}`}`}
                style={
                  badgeShape === 'square' ?
                    { color: skill?.color ?? defaultRoleColor } :
                    { color: skill?.color ?? defaultRoleColor }
                }>
              </span>
            )
          )}
          {badgeOverflow &&
            <span className={`icon-more ${badgeShape === 'square' ?
              classes.additionalSquareBadges :
              classes.additionalCircleBadges
              }`}></span>
          }
        </div>
        {showLabel &&
          <div className={classes.badgeLabel}>Employee Skills</div>
        }
      </div>
      <Popover
        id={"skillsContainer"}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        slotProps={{
          paper: {
            sx: { backgroundColor: 'transparent', border: 'solid 1px transparent', borderRadius: '7px' }
          }
        }}
      >
        <div className={classes.detailedBadges}>
          {employeeSkills?.map((skill, index) => skill?.name &&
            <div key={index} className={classes.skill}>
              <span className={`icon-circle ${classes.skillBadge}`} style={{ color: skill?.color ?? defaultRoleColor }}></span>
              <div className={classes.plane}>
                <span className={`icon-plane`} style={{ color: skill?.color ?? defaultRoleColor }}></span>
                <span className={classes.skillName}>{skill.name}</span>
              </div>
              <div className={classes.endorsements}>
                {skill.endorsements.map((endorsement, index) => 
                  <span key={index}>{endorsement.name}</span>
                )}
              </div>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}

export default EmployeeSkills;