import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";
import { getAllLocations } from "./dropdownService";
import { alphabetSort } from "../../utils/utils";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function getLocations() {
  let response = await getAllLocations();
  return alphabetSort({array: response ?? [], properties: ['value', 'label']});
}

export async function getFunctionalities() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/get-resources/`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return data.load;
    } else {
      console.log("error", data);
      return [];
    }
  } catch (error) {
    console.log(error.message);
    return [];
  }
}

export async function getRoleFolderTree() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/get-folders/`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return data.load;
    } else {
      console.log("error", data);
      return [];
    }
  } catch (error) {
    console.log(error.message);
    return [];
  }
}

export async function saveFolder(folder) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/save-folder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(folder),
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return { success: true, message: "Folder updated", folder: data.load };
    } else {
      console.log("error", data);
      return { success: false, message: data.message, folder: folder };
    }
  } catch (error) {
    console.log(error.message);
    return { success: false, message: "request failed" };
  }
}

export async function saveRole(role) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/save-role`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(role),
    });
    const data = await response.json();
    if (data.responseCode === 200) {
      return { success: true, message: "Role updated", role: data.load };
    } else {
      console.log("error", data);
      return { success: false, message: data.message, role: role };
    }
  } catch (error) {
    console.log(error.message);
    return { success: false, message: "request failed" };
  }
}

export async function deleteRole(roleId) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("roleId", roleId);
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(
      `${API_BASE_URL}/delete-role?` + params.toString(),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.responseCode === 200) {
      return { success: true, message: "Role removed successfully" };
    } else if (data.responseCode === 400) {
      return { success: false, message: "This role is currently in use" };
    } else {
      console.log("error", data);
      return { success: false, message: data.message };
    }
  } catch (error) {
    console.log(error.message);
    return { success: false, message: "request failed" };
  }
}

export async function deleteFolder(folderId) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("folderId", folderId);
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(
      `${API_BASE_URL}/delete-folder?` + params.toString(),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.responseCode === 200) {
      return { success: true, message: "Folder removed successfully" };
    } else if (data.responseCode === 400) {
      return { success: false, message: data.message };
    } else {
      console.log("error", data);
      return { success: false, message: data.message };
    }
  } catch (error) {
    console.log(error.message);
    return { success: false, message: "request failed" };
  }
}