import React, { useState, useEffect, useCallback } from "react";
import Modal from "../UI/Modal";
import classes from './CommentsModal.module.scss';
import { shiftComments, updateComments } from '../../features/shiftCommentsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getComments, sendComments, deleteComments } from '../services/commentsService';
import DeleteComments from "./deleteComment";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";

const CommentsModal = (props) => {
  const shiftId = props.shiftId;
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const [commentValue, setCommentValue] = useState('');
  const commentsUpdate = useSelector(shiftComments);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const handleTextareaChange = (event) => {
    setCommentValue(event.target.value);
  };

  const handleSaveComments = async () => {
    const commentData = {
      comment: commentValue,
      id: null,
      parentId: null,
      shiftId: shiftId
    };
    try {
      const response = await sendComments(commentData);
      setComments((prevComments) => [...prevComments, response]);
      dispatch(updateComments({ id: shiftId, comments: [] }));
      setCommentValue('');
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleClose = () => {
    if (props.onClose) {
      try {
        props.onClose();
      } catch {
        ;
      }
    }
  }

  const fetchComments = useCallback( async() => {
    const fetchedComments = await getComments(shiftId);
    if (Array.isArray(fetchedComments)) {
      setComments(fetchedComments);
    } else {
      console.error('Invalid comments data:', fetchedComments);
    }
  }, [setComments, shiftId]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  useEffect(() => {
    if (commentsUpdate.id === shiftId) {
      fetchComments();
    }
  }, [commentsUpdate, shiftId, fetchComments]);


  const confirmDelete = async (commentId) => {
    try {
      await deleteComments(commentId);
      const updatedComments = comments.filter((item) => item.id !== commentId);
      setComments(updatedComments);
      dispatch(updateComments({ id: shiftId, comments: updatedComments }));
      setCommentToDelete(null);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const cancelDelete = () => {
    setCommentToDelete(null);
  }

  const promptDelete = (commentId, index) => {
    setCommentToDelete({id: commentId, index:index});
  }

  return (<>
    
    <Modal onClose={handleClose} className={`commentsContainer`} maxWidth={"600px"} disabled={commentToDelete !== null}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          <span className={`icon-comment`}></span>
          <div>Add your shift comments</div>
        </div>
        <button className={classes.buttonContainer} onClick={handleClose}>
          <span className={`icon-close ${classes.iconDelete}`}></span>
        </button>
      </div>
      <div className={classes.verticalLine}></div>
      <div className={classes.content}>
        <div style={{ padding: 0, overflowY:'auto'}}>
          {comments.length > 0 ? (
            comments.map((item, index) => (
              <div key={index} className={classes.commentsContainer}>
                <div className={classes.comments}>
                  <div className={classes.commentsHeader}>
                    <div>Comment {comments.length - index }</div>

                    <DeleteComments onClick={()=>promptDelete(item.id, comments.length - index )}/>
                  </div>
                  <div>{item.comment}</div>
                  <div className={classes.commentInfo}>
                    <div className={classes.commentInfo}>
                      <div>
                        {new Date(item.created).toLocaleString('en-US', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true
                        })}
                        {' - '}
                        {item.author}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={classes.emptyComments}>There are no comments in this shift.</div>
          )}
        </div>
        <div style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <textarea
            className={classes.textArea}
            placeholder="Write a comment"
            rows={4}
            cols={5}
            style={{
              resize: "none",
              fontSize: "14px",
              fontFamily: 'poppins',
              padding: '.25em',
            }}
            onChange={handleTextareaChange}
            value={commentValue}
            id="textAreaComments"
            disabled={commentToDelete !== null}
          ></textarea>
          <button
            onClick={handleSaveComments}
            className={`${classes.saveButton} ${commentValue === '' ? classes.disabled : ''}`}
            disabled={commentValue === '' || commentToDelete!==null}
            id="createCommentId"
          >
            Create New Comment
          </button>
        </div>
      </div>

    </Modal> 
    {commentToDelete !== null &&
    <DeleteConfirmationDialog className="deleteCommentsPrompt" message={`Are you sure you want to delete comment ${commentToDelete.index}?`} onCancel={cancelDelete} onConfirm={() => confirmDelete(commentToDelete.id)}/>}
    </>
  )
}

export default CommentsModal;