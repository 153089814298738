import React, { useRef, Fragment, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./LoginForm.module.scss";
import { login } from "../../features/userSlice";
import loginImg from "../../assets/images/login_img_desktop.webp";

import zeusLogo from "../../assets/images/zeus_logo.png";
import InvalidLogin from "../standard/InvalidLogin";
import { loginUser } from "../../components/services/userRegisterService";
import { setLoading } from "../../features/loadingSlice";
import { fetchResourcePermissions } from "../../features/resourcePepermissionsSlice";
import userRegisterContext from "../store/user-register-context";
import {
  setSelectedLocationState,
  setSelectedMonthState,
  setSelectedYearState,
  setloadedLocations,
  updateCurrentLocationId,
  updatecurrentMonthId,
  updatecurrentYearId,
} from "../../features/currentDataSlice";
import { getAllLocations } from "../services/dropdownService";
import { months, getYears, getCurrentMonth, loadYearMonth } from "../services/dateTimeService";
import { HOME, HOME_ID } from "../services/global";
import MicrosoftLoginButton from "./MicrosoftLoginButton";

const LoginForm = () => {
  const userAdminRef = useRef(null);
  const passwordRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRegisterCtx = useContext(userRegisterContext);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const updateCurrentDataSlice = (locations, month, year, locationId) => {
    dispatch(setloadedLocations(locations));
    dispatch(setSelectedLocationState(locations.find(l=>l.id===locationId) ?? locations[0]));
    dispatch(setSelectedMonthState(month));
    dispatch(setSelectedYearState(year));
    dispatch(updateCurrentLocationId(locations.find(l=>l.id===locationId)?.id ?? locations[0].id));
    dispatch(updatecurrentMonthId(month.id));
    dispatch(updatecurrentYearId(year.id));
  }

  const loadResourcer = async () => {
    let locations = await getAllLocations();
    const [year, month, startDate, endDate, locationId] = loadYearMonth();
    if (locations.length >= 0 && month && year) {
      updateCurrentDataSlice(locations, month, year, locationId);
      userRegisterCtx.fetchWeekData(locations.find(loc => loc.id === locationId) ?? locations[0], startDate, endDate, year, month, HOME_ID);
      userRegisterCtx.fetchScheduleTimes();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userAdmin = userAdminRef.current.value;
    const password = passwordRef.current.value;

    if (!validateEmail(userAdmin)) {
      alert("Please enter a valid email.");
      return;
    }

    if (password.length < 5) {
      alert("Password must be at least 5 characters long.");
      return;
    }

    const base64Username = window.btoa(userAdmin);
    const base64Password = window.btoa(password);

    try {
      const userData = await loginUser(base64Username, base64Password);
      dispatch(
        login({
          admin: userData.admin,
          name: userData.name,
          token: userData.token,
          userId: userData.userId,
          username: userData.username,
          active: true,
          resources: userData.resources,
          role: userData.role
        })
      );
      //load resources
      if (userData) {
        loadResourcer();
      }
      dispatch(fetchResourcePermissions("week-view"));
      dispatch(setLoading(false));
      navigate("/home");
    } catch (err) {
      console.log("Login failed:", err.message);
      setDialogOpen(true);
      dispatch(setLoading(false));
    }
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Fragment>
      <div className={classes.loginContainerDesktop}>
        <div className={classes.logingImagesContainer}>
          <img className={classes.loginImg} src={loginImg} alt="" />
          <div className={classes.textContainer}>
            <span className={classes.title}>Work Schedule</span>
            <span className={classes.description}>Management Interface</span>
          </div>
        </div>
        <div className={classes.loginContainerMobile}>
          <div className={classes.colorGreenContainer}></div>
          <div className={classes.loginContainer}>
            <span className={classes.welcomeText}>Welcome</span>

            <img src={zeusLogo} alt="zeusLogo" />
            <span className={classes.companyName}>Employee Scheduling</span>
           
            <MicrosoftLoginButton />

          </div>
          <div className={classes.colorBlackContainer}></div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;