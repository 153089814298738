import { createSlice } from '@reduxjs/toolkit';

const shiftCommentsSlice = createSlice({
  name: 'shiftComments',
  initialState: {id: null, comments: []},
  reducers: {
    updateComments(state, action) {
      state.id= action.payload.id;
      state.comments = action.payload.comments;
    }
  },
});

export const { updateComments } = shiftCommentsSlice.actions;
export const shiftComments = (state) => state.shiftComments

export default shiftCommentsSlice.reducer;