import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./MonthViewHeader.module.scss";
import UserRegisterContext from "../store/user-register-context";
import HamburgerMenu from "../standard/HamburgerMenu";
import Dropdown from "../UI/Dropdown";
import Filter from "../UI/Filter";
import { months, getYears, getCurrentMonth } from "../services/dateTimeService";
import { fetchCurrentMonthView } from "../../features/monthViewSlice";
import { addUserToCosmosDB } from "../../features/cosmosdbSlice";
import {
  currentLocationIdState,
  currentMonthIdState,
  currentYearIdState,
  selectedLocationState,
  selectedMonthState,
  selectedYearState,
  allLocations
} from "../../features/currentDataSlice";
import { MONTHVIEW_ID } from "../services/global";

const MonthViewHeader = ({
  searchQuery,
  isFilterOpen,
  handleSearchChange,
  handleFilterToggle,
  updatedMonth,
  updatedYear
}) => {
  const userRegisterCtx = useContext(UserRegisterContext);
  const selectedMonthSlice = useSelector(selectedMonthState);
  const [selectedMonth, setSelectedMonth] = useState(
    selectedMonthSlice
  );
  const years = getYears();
  const dispatch = useDispatch();

  //Cosmo DB
  const locationId = useSelector(currentLocationIdState);
  const weekId = 0;
  const monthId = useSelector(currentMonthIdState);
  const year = useSelector(currentYearIdState);

  const locations = useSelector(allLocations);
  const selectedLocation = useSelector(selectedLocationState);
  const selectedYear = useSelector(selectedYearState);

  const dispatchCurrentMonthData = ({
    monthId = selectedMonthSlice.id,
    locationId = parseInt(selectedLocation.id),
    year = parseInt(selectedYear.label, 10),
  }) => {
    dispatch(fetchCurrentMonthView({ monthId, locationId, year, userRegisterCtx }));
  };

  useEffect(()=>{
    if (selectedMonth.label !== updatedMonth) {
     const newMonth = months.find((month) => month.label === updatedMonth);
     if (newMonth){ 
       setSelectedMonth(newMonth); 
      //  userRegisterCtx.changeMonth(newMonth, true);
     }
    }
  }, [updatedMonth])
 
  useEffect(()=>{
    let yearObj = getYears().find(y=>y.label === updatedYear + '');
    if (yearObj) {
      // userRegisterCtx.changeYear(yearObj, true);
    }
  }, [updatedYear])

  const changeSelectedLocation = (location) => {
    const locationId = parseInt(location.id);
    dispatchCurrentMonthData({ locationId });
		localStorage.setItem('lastLocation', locationId);
    // userRegisterCtx.changeLocation(location, true);

    //cosmos DB
    dispatch(
      addUserToCosmosDB({
        pageId: MONTHVIEW_ID,
        locationId,
        weekId: 0,
        monthId,
        year,
      })
    );
  };

  const changeSelectedMonth = (objId) => {
    const id = objId.id ? objId.id : objId;
    const selectedMonth = months.find((month) => month.id === id);
    setSelectedMonth(selectedMonth);
    const monthId = selectedMonth.id;
    dispatchCurrentMonthData({ monthId });
    // userRegisterCtx.changeMonth(selectedMonth, true);

    //cosmos DB
    dispatch(
      addUserToCosmosDB({
        pageId: MONTHVIEW_ID,
        locationId,
        weekId: 0,
        monthId,
        year,
      })
    );
  };

  const changeSelectedYear = (yearObj) => {
    const year = parseInt(yearObj.label, 10);
    dispatchCurrentMonthData({ year });
    // userRegisterCtx.changeYear(yearObj, true);

    //cosmos DB
    dispatch(
      addUserToCosmosDB({
        pageId: MONTHVIEW_ID,
        locationId,
        weekId,
        monthId,
        year: yearObj.id,
      })
    );
  };

  const handlePreviousMonth = () => {
    const previousMonthId = selectedMonth.id - 1;
    if (previousMonthId >= 1) {
      changeSelectedMonth(previousMonthId);
    }
  };

  const handleCurrentMonth = () =>{
    const nowMonth = getCurrentMonth(months);
    const currentYear = new Date().getFullYear();
    const yearObj = years.find(y=>y.label === (currentYear + ''));
    setSelectedMonth(nowMonth);
    // userRegisterCtx.changeMonth(nowMonth, true);
    // userRegisterCtx.changeYear(yearObj);
    dispatchCurrentMonthData({monthId: nowMonth.id, year: currentYear});
  }

  const handleNextMonth = () => {
    const nextMonthId = selectedMonth.id + 1;
    if (nextMonthId <= 12) {
      changeSelectedMonth(nextMonthId);
    }
  };

  return (
    <div className={classes.headerBody}>
      <div className={classes.headerIcons}>
        <HamburgerMenu></HamburgerMenu>
        <span className="icon-user-calendar"></span>
        <span className={classes.headerTitle}>User Calendar</span>
      </div>
      <div className={classes.dropdownContainer}>
        <Dropdown
          className={"monthViewDropdown"}
          icon={"icon-location"}
          iconClass={"location"}
          defaultValue={selectedLocation}
          options={locations}
          onClick={changeSelectedLocation}
          showUserInfo={false}
          showSearch={true}
          contentHeight={'50%'}
          displayProperty={'label'}
        ></Dropdown>
        <div className={classes.inputContainer}>
          <span className={`icon-search ${classes.searchIcon}`}></span>
          <input
            type="text"
            placeholder="Search employees"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div onClick={handleFilterToggle} className={classes.filterContainer}>
          <span className={"icon-filter"}>
          </span>
          <span>Filter</span>
        </div>
        {isFilterOpen && (
          <Filter className="monthView" onClose={handleFilterToggle}></Filter>
        )}
        {/* <span>Select Month</span> */}
        <Dropdown
          className={"monthViewDropdown"}
          options={months}
          defaultValue={selectedMonth}
          onClick={changeSelectedMonth}
          showUserInfo={false}
          showSearch={false}
          contentHeight={'50%'}
          displayProperty={'label'}
        ></Dropdown>
        <button onClick={handlePreviousMonth} className={classes.monthBtn}>
          {" "}
          <span className="icon-left-arrow"></span> Previous Month{" "}
        </button>
        <button onClick={handleCurrentMonth} className={classes.monthBtn}>
          Current Month
        </button>
        <button onClick={handleNextMonth} className={classes.monthBtn}>
          {" "}
          Next Month <span className="icon-right-arrow1"></span>{" "}
        </button>
        <Dropdown
          className="yearDropDown"
          options={years}
          defaultValue={selectedYear}
          onClick={changeSelectedYear}
          showUserInfo={false}
          showSearch={false}
          contentHeight={'50%'}
          displayProperty={'label'}
        ></Dropdown>
      </div>
    </div>
  );
};

export default MonthViewHeader;
