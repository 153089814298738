import Cookies from "js-cookie";

import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";
export const getToken = () => Cookies.get("token");

export async function getComments(shiftId) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("shiftId", shiftId);
  const url = API_BASE_URL + "/get-shift-comments?" + params.toString();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function sendComments(commentData) {
  const token = getToken();
  const url = API_BASE_URL + "/save-shift-comment";
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(commentData),
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function deleteComments(commentId) {
  const token = getToken();
  let params = new URLSearchParams();
  params.append("commentId", commentId);
  const url = API_BASE_URL + "/delete-shift-comment?" + params.toString();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.load;
  } catch (error) {
    console.log(error);
    return null;
  }
}