import Cookies from "js-cookie";
import { API_BASE_URL, HEADER_SUBSCRIPTION_KEY } from "./global";

// Function to get the JWT token from the cookie
export const getToken = () => Cookies.get("token");

export async function getShiftPatterns() {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/get-shift-patterns`, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Login failed");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
}

export async function saveShiftPattern(data) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/save-shift-pattern`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(
        "Something went wrong saving pattern"
      );
    }
    const loadedData = await response.json();
    return loadedData;
  } catch (error) {
    console.log(error.message);
  }
}

export async function applyPattern(data) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_BASE_URL}/apply-shift-pattern`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!(response?.ok)) {
      throw new Error(
        "Something went wrong while applying the pattern"
      );
    }
    const loadedData = await response.json();
    return loadedData;
  } catch (error) {
    console.log(error.message);
  }
}

export async function deleteShiftPattern(patternId) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
  const response = await fetch(
    `${API_BASE_URL}/delete-shift-pattern?shiftPatternId=${patternId}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
  } catch(error){
    console.log(error);
  }
}

export async function getPatternApplied(patternId) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }

  try {  const response = await fetch(
    `${API_BASE_URL}/get-shift-pattern-applied?id=${patternId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
  } catch(error) {
    console.log(error);
  }
}

export async function approvePattern(patternId) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(
      `${API_BASE_URL}/approve_shift_pattern_applied?id=${patternId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function deleteAppliedPattern(patternId) {
  const token = getToken();
  if (!token) {
    throw new Error("No token found");
  }
  try {
    const response = await fetch(
      `${API_BASE_URL}/delete_shift_pattern_applied?id=${patternId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": HEADER_SUBSCRIPTION_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.log(error);
  }
}