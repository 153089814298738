import React, { useEffect, useRef, useState } from "react";
import "./DatePicker.scss";
import { DayPicker } from 'react-day-picker';
import { DateCalendar, LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ClickOutsideComponent from "../UI/ClickOutsideComponent";
import { ThemeProvider, createTheme } from "@mui/material";

const newTheme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          color: 'black',
          borderRadius: 4,
          borderWidth: 0,
          borderColor: '#00ca9a',
          border: '0px solid',
          backgroundColor: '#eFeFeF',
          fontSize: '0.85em',
          fontWeight: 'bold'
        },
        today: {
          borderRadius: 4,
          borderWidth: 0,
        },
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontFamily:"Poppins",
          fontWeight: 'bold',
          fontSize: '0.85em'
        }
      }
    },
    MuiPickersCalendarHeader:{
      styleOverrides: {
        root:{
          margin: '0.5em 0'
        },
        label: {
          fontFamily: "Poppins",
          fontSize: '0.9em'
        }
      }
    }
  }
})

const DatePicker = ({ onSaveDate, defaultDate, title }) => {
  const [selectedDay, setSelectedDay] = useState(null);
  const selectedDayReference = useRef();

  useEffect(() => {
    if (selectedDay?.isSame(selectedDayReference.current)) {
      onSaveDate(selectedDay);
    }
    selectedDayReference.current = selectedDay;
  }, [selectedDay])

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const saveDateHandler = () => {
    if (selectedDay) {
      onSaveDate(selectedDay);
    } else {
      onSaveDate();
    }
  };

  const selectedYearMonth = (event) => {
    selectedDayReference.current = null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ClickOutsideComponent handleClickOutside={() => onSaveDate()}>
        <div className="calendarContainer">
          <div className="headerContainer">
            <span>{title ?? ''}</span>
            <button onClick={saveDateHandler}>ok</button>
          </div>
          <ThemeProvider theme={newTheme}>
            <DateCalendar value={selectedDay} onChange={handleDayClick} views={['year', 'month', 'day']} onYearChange={selectedYearMonth} onMonthChange={selectedYearMonth}/>
          </ThemeProvider>
        </div>
      </ClickOutsideComponent>
    </LocalizationProvider>

  );
};

export { DatePicker };
