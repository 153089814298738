import React from 'react';
import classes from './Loading.module.scss';
import loadingImage from '../../assets/gifs/loading.gif'

const LoadingComponent = () => {

  return (
    <div className={classes.backdrop}>
      <img alt="" className={classes.loadingGif} src={loadingImage} />
    </div>
  );
}

export default LoadingComponent