import React, { useState } from "react";
import { format } from 'date-fns';
import classes from "./AddNewVacation.module.scss";
import { DatePicker } from "../datepicker/DatePicker";
import InitialAndFinalDate from "../calendar/InitialAndFinalDate";

const AddNewVacation = ({ closeAddNewVacay, employeeId }) => {
  const [startDate, setStartDate] = useState({date: null, formattedDate: ""});
  const [endDate, setEndDate] = useState({date: null, formattedDate: ""});
  const [activeStartDate, setActiveStartDate] = useState(false);
  const [activeEndDate, setActiveEndDate] = useState(false);

  const saveEmployeeVacationHandler = () => {
    const newVacay = {
      employee: { id: employeeId },
      startDate: startDate.date,
      endDate: endDate.date,
      vacation: true
    };

    if (newVacay.startDate.date !== null && newVacay.endDate.date !== null) {
      closeAddNewVacay(newVacay);
    } else {
      closeAddNewVacay();
    }
  };

  const toggleActiveStartDate = () => {
    if (activeEndDate === true) {
      setActiveEndDate(false);
    }
    setActiveStartDate(!activeStartDate);
  };

  const toggleActiveEndDate = () => {
    if (activeStartDate === true) {
      setActiveStartDate(false);
    }
    setActiveEndDate(!activeEndDate);
  };

  const onSaveDate = (day) => {
    if (day) {
      const dateTotransform = new Date(day);
      const date = format(dateTotransform, "yyyy-MM-dd");
      const formattedDate = format(dateTotransform, "MM/dd/yyyy");
      const dateObj = {
        date,
        formattedDate
      }
      if (activeStartDate === true) {
        setStartDate(dateObj);
      }
      if (activeEndDate === true) {
        setEndDate(dateObj);
      }
    }
    if (activeStartDate === true) {
      setActiveStartDate(false);
    }
    if (activeEndDate === true) {
      setActiveEndDate(false);
    }
  };

  const onSaveDateHandler = (day, type) => {
    if (type === 0) setStartDate(day);
    if (type === 1) setEndDate(day);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>+ New Vacation Period</span>
        <button
          onClick={saveEmployeeVacationHandler}
          className={classes.saveBtn}
        >
          Save
        </button>
        <button onClick={closeAddNewVacay} className={classes.closeBtn}>
          <span className={`icon-close ${classes.iconClose}`}></span>
        </button>
      </div>
      <div className={classes.inputContainer}>
        <InitialAndFinalDate onSaveDateHandler={onSaveDateHandler}/>
      </div>
    </div>
  );
};

export { AddNewVacation };