import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { insertCurrentDataCosmo } from "../components/services/cosmoDBService";
import { setShowErrorPage } from "./currentDataSlice";

const cosmosdbSlice = createSlice({
  name: "cosmosdb",
  initialState: {
    connectedUsers: [],
  },
  reducers: {
    setConnectedUsers: (state, action) => {
      state.connectedUsers = action.payload;
    },
    removeCurrentConnectedUsers: (state, action) => {
      state.connectedUsers = action.payload;
    },
    updateCurrentUsers: (state, action) => {
      const newUsers = action.payload;
      state.connectedUsers.push(newUsers);
    },
  },
});

export const addUserToCosmosDB = createAsyncThunk(
  "cosmosdb/addUserToCosmosDB",
  async (
    { pageId, locationId, weekId, monthId, year },
    { getState, dispatch }
  ) => {
    try {
      const dataCosmo = {
        pageId: pageId.toString(),
        locationId: locationId.toString(),
        year: year.toString(),
        month: monthId.toString(),
        weekId: weekId.toString(),
      };
      const response = await insertCurrentDataCosmo(dataCosmo);
      if (response.responseCode === 200) {
        dispatch(setConnectedUsers(response.load));
      } else {
        dispatch(setShowErrorPage(true))
      }
    } catch (error) {
      console.log(error.message);
    }
  }
);

export default cosmosdbSlice.reducer;
export const {
  setConnectedUsers,
  updateCurrentUsers,
  removeCurrentConnectedUsers,
} = cosmosdbSlice.actions;
export const connectUsersState = (state) => state.cosmosdb.connectedUsers;