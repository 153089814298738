import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { getEmployeeLeaves, getAllLeaves } from "../services/employeePermissionService";
import { logOut } from "../../features/userSlice";
import { restartPermissions } from "../../features/resourcePepermissionsSlice";
import UserRegisterContext from "../store/user-register-context";

const useLogout = () => {
  const dispatch = useDispatch();
  const userRegisterCtx = useContext(UserRegisterContext)
  const handleLogout = () => {
    dispatch(logOut());
    userRegisterCtx.resetData();
    dispatch(restartPermissions());
  };
  return handleLogout;
};

const useEmployeeLeaves = (employeeId) => {
  const [employeeLeaves, setEmployeeLeaves] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [sickLeaves, setSickLeaves] = useState([]);
  const [trainingLeaves, setTrainingLeaves] = useState([]);
  const [travelLeaves, setTravelLeaves] = useState([]);

  const handleLogout = useLogout();

  const transformDate = (date) => {
    const dateObj = new Date(date);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
  
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const fetchLeaves = async () => {
    try {
      const dataLoad = await getAllLeaves();
      if (dataLoad.load) {
        const leavesLoad = [];
        const sickLeavesLoad = [];
        const trainingLeavesLoad = [];
				const travelLeavesLoad = [];
        for (const key in dataLoad.load) {
          if (dataLoad.load[key].type.toUpperCase() === "M") {
            leavesLoad.push({
              ...dataLoad.load[key],
              label: dataLoad.load[key].name
            });
          }
          if (dataLoad.load[key].type.toUpperCase() === "S") {
            sickLeavesLoad.push({
              ...dataLoad.load[key],
              label: dataLoad.load[key].name
            });
          }
          if (dataLoad.load[key].type.toUpperCase() === "T") {
            trainingLeavesLoad.push({
              ...dataLoad.load[key],
              label: dataLoad.load[key].name
            });
          }
					if (dataLoad.load[key].type.toUpperCase() === "A") {
            travelLeavesLoad.push({
              ...dataLoad.load[key],
              label: dataLoad.load[key].name
            });
          }
      }
        setLeaves(leavesLoad);
        setSickLeaves(sickLeavesLoad);
        setTrainingLeaves(trainingLeavesLoad);
				setTravelLeaves(travelLeavesLoad);
      }
      if (dataLoad.status === 403) {
        handleLogout();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchEmployeesLeaves = async () => {
    try {
      if (employeeId) {
        const leavesLoad = await getEmployeeLeaves(employeeId);
        if (leavesLoad.load) {
          const data = [];
          for (const key in leavesLoad.load) {
              const startDate = transformDate(leavesLoad.load[key].startDate);
              const endDate = transformDate(leavesLoad.load[key].endDate)
              data.push({
              ...leavesLoad.load[key],
              startDate: startDate,
              endDate: endDate
            });
          }
          setEmployeeLeaves(data)
        } 
        if (leavesLoad.status === 403) {
          handleLogout();
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (leaves.length === 0) {
      fetchLeaves();
    }
    fetchEmployeesLeaves();
  }, [employeeId]);

  const refetchEmployeeLeaves = () => {
    fetchEmployeesLeaves();
  }

  return {
    employeeLeaves,
    leaves,
    sickLeaves,
    trainingLeaves,
		travelLeaves,
    setEmployeeLeaves,
    refetchEmployeeLeaves ,
    fetchLeaves
  };
};

export default useEmployeeLeaves;
