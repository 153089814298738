import { createSlice } from "@reduxjs/toolkit";

const currentDataSlice = createSlice({
  name: "currentData",
  initialState: {
    loadedLocations: [],
    countries: [],
    states: [],
    cities: [],
    sites: [],
    timeZones: [],
    selectedLocation: {},
    selectedMonth: {},
    selectedYear: {},
    currentPageId: 0,
    currentlocationId: 0,
    currentYearId: 0,
    currentMonthId: 0,
    currentWeekId: 0,
    showErrorPage: false,
    newLocation: -1,
  },
  reducers: {
    setloadedLocations: (state, action) => {
      const previousLocations = state.loadedLocations;
      const newLocation = getNewLocation(previousLocations, action.payload);
      state.loadedLocations = action.payload;
			let updatedSelectedLocation = state.loadedLocations.find(loc => loc.id === state.selectedLocation?.id) ?? {}
			if (typeof(updatedSelectedLocation.id) === 'number' ) {
				state.selectedLocation = updatedSelectedLocation;
			}
      if (newLocation?.id) {
        state.newLocation = newLocation.id;
      }
    },
    resetNewLocation: (state) =>{
      state.newLocation = -1;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setTimeZones: (state, action) => {
      state.timeZones = action.payload;
    },
    setSelectedLocationState: (state, action) => {
      state.selectedLocation = action.payload;
      state.currentlocationId = action.payload.id;
    },
    setSelectedMonthState: (state, action) => {
      state.selectedMonth = action.payload;
      state.currentMonthId = action.payload.id;
    },
    setSelectedYearState: (state, action) => {
      state.selectedYear = action.payload;
      state.currentYearId = action.payload.id;
    },
    updatecurrentPageId: (state, action) => {
      state.currentPageId = action.payload;
    },
    updateCurrentLocationId: (state, action) => {
      state.currentlocationId = action.payload;
    },
    updatecurrentYearId: (state, action) => {
      state.currentYearId = action.payload;
    },
    updatecurrentMonthId: (state, action) => {
      state.currentMonthId = action.payload;
    },
    updatecurrentWeekId: (state, action) => {
      state.currentWeekId = action.payload;
    },
    cleanCurrentData: (state) => {
      state.loadedLocations = [];
      state.selectedLocation = {};
      state.selectedMonth = {};
      state.selectedYear = {};
      state.currentPageId = 0;
      state.currentlocationId = 0;
      state.currentYearId = 0;
      state.currentMonthId = 0;
      state.currentWeekId = 0;
    },
    setShowErrorPage: (state, action) => {
      state.showErrorPage = action.payload;
    }
  },
});

export default currentDataSlice.reducer;
export const {
  setloadedLocations,
  setCountries,
  setStates,
  setCities,
  setSites,
  setTimeZones,
  setSelectedLocationState,
  setSelectedMonthState,
  setSelectedYearState,
  updatecurrentPageId,
  updateCurrentLocationId,
  updatecurrentYearId,
  updatecurrentMonthId,
  updatecurrentWeekId,
  cleanCurrentData,
  setShowErrorPage,
  resetNewLocation
} = currentDataSlice.actions;

export const allLocations = (state) => state.currentData.loadedLocations;
export const allCountries = (state) => state.currentData.countries;
export const allStates = (state) => state.currentData.states;
export const allCities = (state) => state.currentData.cities;
export const allSites = (state) => state.currentData.sites;
export const allTimeZones = (state) => state.currentData.timeZones;
export const selectedLocationState = (state) => state.currentData.selectedLocation;
export const selectedMonthState = (state) => state.currentData.selectedMonth;
export const selectedYearState = (state) => state.currentData.selectedYear;
export const showErrorPageState =(state) => state.currentData.showErrorPage;

export const currentPageIdState = (state) => state.currentData.currentPageId;
export const currentLocationIdState = (state) => state.currentData.currentlocationId;
export const currentYearIdState = (state) => state.currentData.currentYearId;
export const currentMonthIdState = (state) => state.currentData.currentMonthId;
export const currentWeekIdState = (state) => state.currentData.currentWeekId;
export const selectNewLocation = (state) => state.currentData.newLocation;

function getNewLocation (previousLocations, currentLocations) {
  if (currentLocations?.length === (previousLocations?.length ?? -1) + 1) {
    const prevIds = new Set(previousLocations.map(location => location.id));
    return currentLocations.find(location => !prevIds.has(location.id))
  } else {
    return undefined;
  }

}